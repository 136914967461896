import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../common/Modal";
import CustomerForm from "../forms/CustomerForm";
import { closeModal } from "../../state/slices/frontend";
import UserForm from "../forms/UserForm";
import FloorPlanForm from "../forms/FloorPlanForm";
import CityFormForm from "../forms/CityFormForm";
import EkotropeSyncForm from "../forms/EkotropeSyncForm";
import { PDFPreview } from "../common/PDFPreview";

const modalComponents = {
  customer: { Component: CustomerForm, modalProps: {} },
  user: { Component: UserForm, modalProps: {} },
  floorPlan: { Component: FloorPlanForm, modalProps: {} },
  cityForm: { Component: CityFormForm, modalProps: {} },
  ekotropeSync: { Component: EkotropeSyncForm, modalProps: {} },
  pdf: { Component: PDFPreview, modalProps: { large: true } },
};

export function AllModalForms() {
  const dispatch = useDispatch();
  const { modals } = useSelector(state => state.frontend);

  return (
    <>
      {Object.entries(modalComponents).map(([modalName, { Component, modalProps }]) => {
        const { isOpen, isModified } = modals[modalName];
        return (
          <Modal
            key={modalName}
            modalShowing={isOpen}
            closeModal={() => {
              if (!isModified) return dispatch(closeModal({ modal: modalName }));
              if (window.confirm("You have unsaved changes. Are you sure you want to cancel?"))
                dispatch(closeModal({ modal: modalName }));
            }}
            {...modalProps}
          >
            <Component />
          </Modal>
        );
      })}
    </>
  );
}
