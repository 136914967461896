import axios from "axios";
import { getIsOnBuilderWebsite } from "../utils/isBuilderWebsite";

export const loginUser = async payload => {
  const { data } = await axios.post(`/api/auth/signin`, payload);
  return data;
};

export const loginBuilderUser = async payload => {
  const { data } = await axios.post(`/api/auth/builder/signin`, payload);
  return data;
};

export const checkStatus = async () => {
  const isOnBuilderSite = getIsOnBuilderWebsite();
  const path = isOnBuilderSite ? "/api/auth/builder/status" : "/api/auth/status";
  const { data } = await axios.get(path);
  return data;
};
