import React from "react";
import { SmallGreenButton, SmallRedButton } from "../common/Buttons";
import FormHeader from "../common/forms/FormHeader";
import { Formik, Form } from "formik";
import FormikFieldWithLabelAndError from "../common/FormikFieldWithLabel";
import { useUserOptions } from "../../state/slices/userSlice";

export function InspectionFiltersForm({ searchHandler, filters = {} }) {
  // const customerOptions = useSelector(selectCustomerOptions);
  const userOptions = useUserOptions();

  const initialValues = {
    earliestDate: filters.earliestDate || "",
    latestDate: filters.latestDate || "",
    raterId: filters.raterId || "",
  };

  // Cleanup search data and call parent search handler
  const handleSubmit = data => {
    searchHandler(data);
  };

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    searchHandler({});
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      <Form className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg">
        <FormHeader className="col-span-2">Search Inspections</FormHeader>
        <p className="col-span-2">Note: only homes with scheduled inspections are shown.</p>
        {/* <FormikFieldWithLabelAndError
          name="earliestDate"
          label="Earliest Date"
          className="col-span-1"
          type="date"
        />
        <FormikFieldWithLabelAndError
          name="latestDate"
          label="Latest Date"
          className="col-span-1"
          type="date"
        /> */}
        <FormikFieldWithLabelAndError name="raterId" label="Inspector" as="select">
          <option value="">---</option>
          {userOptions.map(user => (
            <option value={user.value} key={user.value}>
              {user.label}
            </option>
          ))}
        </FormikFieldWithLabelAndError>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} type="button" className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </Form>
    </Formik>
  );
}
