import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import * as Customer from "../../services/customer";
import { flashSuccessNotification } from "./notificationSlice";

// selectors

export const selectCustomerOptions = state =>
  state.customer.customerOptions.map(c => ({ value: c.id, label: c.name }));

export const selectContactsOptions = state => {
  return {
    requestors: state.customer.customerContactsOptions.requestors.map(c => ({
      value: c.id,
      label: `${c.name} - ${c.email} - ${c.phone}`,
      customerId: c.customerId,
    })),
    inspectionPOCs: state.customer.customerContactsOptions.inspectionPOCs.map(c => ({
      value: c.id,
      label: `${c.name} - ${c.email} - ${c.phone}`,
      customerId: c.customerId,
    })),
  };
};

export const selectAllCustomerNamesAndIds = state =>
  state.customer.customerOptions.map(c => ({ id: c.id, name: c.name }));

export const selectCustomerNamesWithPlantIds = state =>
  state.plant.plants.map(p => ({ PlantId: p.id, name: p.Customer?.name }));

export const useCustomerIdFromPlantId = plantId => {
  return useSelector(state => state.plant.plants.find(p => p.id === plantId)?.CustomerId);
};

// actions

export const readAllCustomerOptions = createAsyncThunk("customers/fetchOptions", async () => {
  try {
    const customerOptions = await Customer.getAllOptions();
    return customerOptions;
  } catch (error) {
    console.error("Failed to fetch customer options", error);
  }
});

export const readCustomerContactOptions = createAsyncThunk(
  "customers/fetchContactOptions",
  async () => {
    try {
      const customerContactsOptions = await Customer.getAllContactOptions();
      return customerContactsOptions;
    } catch (error) {
      console.error("Failed to fetch customer contact options", error);
    }
  },
);

export const upsertCustomerAsync = createAsyncThunk(
  "customer/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let response;
      response = await axios.post(`/api/customer`, payload);
      dispatch(readAllCustomerOptions());
      return { customer: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  },
);

export const deleteCustomerAsync = createAsyncThunk(
  "customer/delete",
  async (payload, { dispatch }) => {
    await axios.delete(`/api/customer/${payload.id}`);
    dispatch(flashSuccessNotification("Customer deleted successfully."));
    dispatch(readAllCustomerOptions());
    return { id: payload.id };
  },
);

export const getUsersCSVAsync = createAsyncThunk("customer/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/customer/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "customers.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const revalidateCustomerIdAsync = createAsyncThunk(
  "customer/revalidateCustomerIdAsync",
  async payload => {
    if (!payload.id) return { id: null };
    const { data: customerIdExists } = await axios.get(`/api/customer/validate/${payload.id}`);
    if (!customerIdExists) return { id: null };
    return { id: payload.id };
  },
);

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    // customers is the paginated detailed data we
    // use for display and editing
    customers: [],
    // customerOptions is used to populate dropdowns
    // contains all customers but with fewer details
    // contains { id, name } , use a selector that maps
    // onto { label, values }
    customerOptions: [],
    customerContactsOptions: { requestors: [], inspectionPOCs: [] },
    customerId: undefined,
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllCustomerOptions.fulfilled]: (state, action) => {
      state.customerOptions = action.payload;
    },
    [readCustomerContactOptions.fulfilled]: (state, action) => {
      state.customerContactsOptions = action.payload;
    },
    [upsertCustomerAsync.pending]: state => {
      state.loading = true;
    },
    [upsertCustomerAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.customer[1]) {
        // insert
        if (action.payload.customer[0]) state.customers.unshift(action.payload.customer[0]);
      } else {
        // update
        state.customers = (state.customers || []).map(customer =>
          customer.id === action.payload.customer[0].id ? action.payload.customer[0] : customer,
        );
      }
    },
    [upsertCustomerAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [deleteCustomerAsync.pending]: state => {
      state.loading = true;
    },
    [deleteCustomerAsync.fulfilled]: (state, action) => {
      state.loading = false;
      // state.customers = state.customers.filter(customer => customer.id !== action.payload.id);
    },
    [deleteCustomerAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [revalidateCustomerIdAsync.pending]: state => {
      state.loading = true;
    },
    [revalidateCustomerIdAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.id) {
        state.customerId = action.payload.id;
      } else if (action.payload.id === null) {
        state.customerId = null;
        state.generalError = "Customer id is invalid.";
      }
    },
    [revalidateCustomerIdAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.customerId = null;
      state.generalError = "Customer id is invalid.";
    },
  },
});
export const { clearError, clearErrors } = customerSlice.actions;
export default customerSlice.reducer;
