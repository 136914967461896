import axios from "axios";

export const getAll = async params => {
  const { data } = await axios.get("/api/customers", { params });
  return { customers: data.rows, pages: data.pages };
};

export const getAllOptions = async () => {
  const { data } = await axios.get("/api/customer/options");
  return data;
};

export const getAllContactOptions = async () => {
  const { data } = await axios.get("/api/customer/contacts/options");
  return data;
};

export async function validateBuilderNameUnique(name) {
  const route = "/api/customer/validate";
  const response = await axios.get(route, { params: { name } });
  return response.data;
}
