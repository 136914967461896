import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, modifyModal } from "../../state/slices/frontend";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import { upsertUserAsync } from "../../state/slices/userSlice";
import { Formik, Form } from "formik";
import { SubmitButton } from "../common/Buttons";

export default function UserForm() {
  const { modals } = useSelector(state => state.frontend);
  const [isModified, setIsModified] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isModified) dispatch(modifyModal({ modal: "user" }));
  }, [isModified, dispatch]);

  const handleSubmitUser = values => {
    dispatch(upsertUserAsync(values));
    dispatch(closeModal({ modal: "user" }));
  };
  const initialValues = modals.user.target || {
    name: "",
    email: "",
    RTIN: "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmitUser}>
      {({ handleChange }) => {
        function handleInputChange(e) {
          setIsModified(true);
          handleChange(e);
        }
        return (
          <Form className="p-4">
            <h3 className="font-bold text-2xl mb-4">Edit User</h3>
            <div className="grid grid-cols-3 gap-x-4">
              <FormikFieldWithLabel
                className="col-span-1"
                name="name"
                label="Name"
                onChange={handleInputChange}
              />
              <FormikFieldWithLabel
                className="col-span-1"
                name="email"
                label="Email Address"
                disabled
              />
              <FormikFieldWithLabel
                className="col-span-1"
                name="RTIN"
                label="RTIN"
                onChange={handleInputChange}
              />
            </div>
            <div className="flex justify-end m-4 mt-6">
              <SubmitButton />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
