import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HomeService from "../../services/home";
import HeadingWithTabsAndActions from "../common/HeadingWithTabsAndActions";
import TableButton from "../common/TableButton";
import { PencilIcon } from "@heroicons/react/outline";
import RequestInspectionFormModal from "../forms/RequestInspectionFormModal";

const tabs = ["General", "Modeling", "Inspections"];

const HomeDetails = () => {
  const { id } = useParams();
  const [home, setHome] = useState(null);
  const [tab, setTab] = React.useState(tabs[0]);

  const fetchHome = useCallback(async () => {
    try {
      const fetchedHome = await HomeService.get(id);
      setHome(fetchedHome);
    } catch (error) {
      console.error("Error fetching home:", error);
    }
  }, [id, setHome]);

  useEffect(() => {
    fetchHome();
  }, [id]);

  if (!home) return <p>Loading home details...</p>;

  const homeWithoutInspections = { ...home };
  delete homeWithoutInspections.Inspections;

  return (
    <div className="m-4 mx-auto max-w-xl">
      <HeadingWithTabsAndActions tabs={tabs} currentTab={tab} onChange={setTab} />
      {/* <br /> */}
      {tab === "General" && <GeneralTab home={homeWithoutInspections} />}
      {tab === "Modeling" && <ModelingTab home={homeWithoutInspections} />}
      {tab === "Inspections" && (
        <InspectionsTab home={home} inspections={home.Inspections} fetchHome={fetchHome} />
      )}
    </div>
  );
};

function DataRow({ label, value }) {
  if (value === null || value === undefined) return null;
  if (Array.isArray(value))
    return (
      <div className="mt-1">
        <strong>{label}</strong>
        <div className="ml-4">
          {value.map((val, index) => {
            return <DataRow key={index} label={`${label} ${index}`} value={val} />;
          })}
        </div>
      </div>
    );

  if (typeof value === "object")
    return (
      <div className="mt-1">
        <strong>{label}</strong>
        <div className="ml-4">
          {Object.keys(value).map((key, index) => {
            const val = value[key];
            return <DataRow key={key} label={key} value={val} />;
          })}
        </div>
      </div>
    );

  return (
    <div className="flex justify-between mt-1 border-b border-gray-300">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
  );
}

function GeneralTab({ home }) {
  return (
    <div className="my-4">
      {Object.keys(home).map((key, index) => {
        const value = home[key];
        if (!fieldNamesMapGeneralTab[key] || !value) return null;
        else return <DataRow key={index} label={fieldNamesMapGeneralTab[key]} value={value} />;
      })}
    </div>
  );
}

function ModelingTab({ home }) {
  return (
    <div className="my-4">
      {Object.keys(home).map((key, index) => {
        const value = home[key];
        if (!fieldNamesMapModelingTab[key] || !value) return null;
        else return <DataRow key={index} label={fieldNamesMapModelingTab[key]} value={value} />;
      })}
    </div>
  );
}

function InspectionsTab({ inspections, home, fetchHome }) {
  const [editingInspection, setEditingInspection] = useState(null);
  const [isInspectionFormModalOpen, setIsInspectionFormModalOpen] = useState(false);

  return (
    <div className="my-4">
      {inspections.map((inspection, index) => {
        return (
          <div
            key={index}
            className={`border py-2 my-4 ${inspection.status === "requested" && "flex items-center"}`}
          >
            <div className="flex-1">
              {Object.keys(inspection).map((key, index) => {
                const value = inspection[key];
                if (!fieldNamesMapInspectionsTab[key] || !value) return null;
                else
                  return (
                    <DataRow key={index} label={fieldNamesMapInspectionsTab[key]} value={value} />
                  );
              })}
            </div>
            {inspection.status === "requested" && (
              <TableButton
                testId={`request-edit-${inspection.id}`}
                onClick={e => {
                  e.stopPropagation();
                  setEditingInspection(inspection);
                  setIsInspectionFormModalOpen(true);
                }}
                Icon={PencilIcon}
                className="text-indigo-600 mx-3"
              />
            )}
          </div>
        );
      })}
      {home && editingInspection && (
        <RequestInspectionFormModal
          home={home}
          editingInspection={editingInspection}
          isOpen={isInspectionFormModalOpen}
          onClose={() => {
            setIsInspectionFormModalOpen(false);
            setEditingInspection(null);
          }}
          refreshHomes={fetchHome}
        />
      )}
    </div>
  );
}

const fieldNamesMapGeneralTab = {
  id: "ID",
  serialNumber: "Serial Number",
  status: "Status",
  city: "City",
  state: "State",
  zip: "Zip",
  address: "Address",
  modeler: "Modeler",
  fieldVerifier: "Field Verifier",
  qaVerifier: "QA Verifier",
  hersVerifier: "HERS Verifier",
  homeRequestorPOC: "Home Requestor POC",
  homeInspectionPOC: "Home Inspection POC",
  pathOfCompliance: "Path of Compliance",
  testingType: "Testing Type",
  notes: "Notes",
  reportsRequired: "Reports Required",
  createdAt: "Created At",
  updatedAt: "Updated At",
};

const fieldNamesMapInspectionsTab = {
  id: "ID",
  inspectionType: "Inspection Type",
  schedulerNotes: "Scheduler Notes",
  inspectionNotes: "Inspection Notes",
  tripChargeOrReInspect: "Trip Charge Or ReInspect",
  actualCFM50: "Actual CFM50",
  bdResultsCFM50: "BD Results CFM50",
  actualACH: "Actual ACH",
  actualCFM25ForDuctTest: "Actual CFM25 For Duct Test",
  actualCFM25ForDuctTestPerSqft: "Actual CFM25 For Duct Test Per Sqft",
  actualCFM25ForDuctTestES: "Actual CFM25 For Duct Test ES",
  actualCFM25ForLTO: "Actual CFM25 For LTO",
  actualWindowUValue: "Actual Window U Value",
  actualWindowSHGC: "Actual Window SHGC",
  passFail: "Pass Fail",
};

const fieldNamesMapModelingTab = {
  ekotropeProjectId: "Ekotrope Project ID",
  ekotropeAlgorithmVersion: "Ekotrope Algorithm Version",
  ekotropeProjectName: "Ekotrope Project Name",
  ekotropeProjectStatus: "Ekotrope Project Status",
  ekotropePlanId: "Ekotrope Plan ID",
  isProjectLockedInEkotrope: "Is Project Locked in Ekotrope",
  ekotropeNotes: "Ekotrope Notes",
  isAffordableDevelopment: "Is Affordable Development",
  model: "Model",

  ekotropeHousePlanName: "Ekotrope House Plan Name",
  totalNBedrooms: "Total Number of Bedrooms",
  garage: "Garage",
  hasElectricVehicleReadySpace: "Has Electric Vehicle Ready Space",
  naturalVentilation: "Natural Ventilation",
  numberOfFloorsOnOrAboveGrade: "Number of Floors On or Above Grade",
  orientation: "Orientation",
  programmableThermostat: "Programmable Thermostat",
  buildingType: "Building Type",
  residenceType: "Residence Type",
  unitsInBuilding: "Units in Building",
  lighting: "Lighting",
  clothesDryer: "Clothes Dryer",
  clothesWasher: "Clothes Washer",
  dishwasher: "Dishwasher",
  rangeOven: "Range Oven",
  refrigeratorConsumption: "Refrigerator Consumption",
  refrigeratorIsOutsideConditionedSpace: "Refrigerator Is Outside Conditioned Space",
  hasSolarGeneration: "Has Solar Generation",
  mechanicalEquipments: "Mechanical Equipments",
  coolingHVACSystems: "Cooling HVAC Systems",
  waterHeaters: "Water Heaters",
  mechanicalDistributionSystems: "Mechanical Distribution Systems",
  mechanicalVentilation: "Mechanical Ventilation",
  thermostats: "Thermostats",
  aboveGradeThermalBoundaryArea: "Above Grade Thermal Boundary Area",
  ceilingArea: "Ceiling Area",
  conditionedArea: "Conditioned Area",
  conditionedVolume: "Conditioned Volume",
  framedFloorArea: "Framed Floor Area",
  slabArea: "Slab Area",
  totalThermalBoundaryArea: "Total Thermal Boundary Area",
  wallArea: "Wall Area",
  windowArea: "Window Area",
  foundationType: "Foundation Type",
  infiltrationAch50: "Infiltration ACH50",
  infiltrationCfm50: "Infiltration CFM50",
  infiltrationCoolingNaturalACH: "Infiltration Cooling Natural ACH",
  infiltrationEffectiveLeakageArea: "Infiltration Effective Leakage Area",
  infiltrationFieldTestStatus: "Infiltration Field Test Status",
  infiltrationHeatingNaturalACH: "Infiltration Heating Natural ACH",
  infiltrationShelterClass: "Infiltration Shelter Class",
  infiltrationSpecificLeakageArea: "Infiltration Specific Leakage Area",
  foundationWalls: "Foundation Walls",
  skylights: "Skylights",
  slabs: "Slabs",
  framedFloors: "Framed Floors",
  walls: "Walls",
  rimJoists: "Rim Joists",
  ceilings: "Ceilings",
  doors: "Doors",
  windows: "Windows",
  waterSystemAllHotWaterPipesInsulatedToAtLeastR3:
    "Water System: All Hot Water Pipes Insulated to at Least R3",
  waterSystemFixtureEffectiveness: "Water System Fixture Effectiveness",
  waterSystemHotWaterPipeLength: "Water System Hot Water Pipe Length",
  waterSystemUsingEstimatedHotWaterPipeLength: "Water System Using Estimated Hot Water Pipe Length",

  modelingReport: "Modeling Report",
  modelingCompletionDate: "Modeling Completion Date",
  completionDate: "Completion Date",
  thermalEnvelopeReport: "Thermal Envelope Report",
  thermalEnvelopeCompletionDate: "Thermal Envelope Completion Date",
  projectedReports: "Projected Reports",
  projectedReportEmailedToBuilderDate: "Projected Report Emailed to Builder Date",
  manualJReport: "Manual J Report",
  manualJRequestedDate: "Manual J Requested Date",
  manualJReceivedDate: "Manual J Received Date",
  confirmedReport: "Confirmed Report",
  confirmedReportEmailedToBuilderDate: "Confirmed Report Emailed to Builder Date",
  energyStarDocumentationReceivedDate: "Energy Star Documentation Received Date",
  energyStarHVACDesignDocuments: "Energy Star HVAC Design Documents",
  energyStarHVACDesignReviewDocument: "Energy Star HVAC Design Review Document",
  energyStarDocumentationReviewCompletedDate: "Energy Star Documentation Review Completed Date",
  airInfiltationReport: "Air Infiltration Report",
  airInfiltationReportEmailedToBuilderDate: "Air Infiltration Report Emailed to Builder Date",
  breakerBoxLabel: "Breaker Box Label",
  breakerBoxLabelEmailedToBuilderDate: "Breaker Box Label Emailed to Builder Date",
  hersRating: "HERS Rating",
  hersRatingType: "HERS Rating Type",
  hersRatingDate: "HERS Rating Date",
  insulation: "Insulation",
  foam: "Foam",
  acDd: "AC DD",
  electricResistance: "Electric Resistance",
  heatPump: "Heat Pump",
  gasHeat: "Gas Heat",
  firstFloorNCorners: "First Floor Number of Corners",
  firstFloorNBedrooms: "First Floor Number of Bedrooms",
  firstFloorSqft: "First Floor Square Feet",
  firstFloorAvgHeight: "First Floor Average Height",
  firstFloorVolume: "First Floor Volume",
  secondFloorNCorners: "Second Floor Number of Corners",
  secondFloorNBedrooms: "Second Floor Number of Bedrooms",
  secondFloorSqft: "Second Floor Square Feet",
  secondFloorAvgHeight: "Second Floor Average Height",
  secondFloorVolume: "Second Floor Volume",
  averageCeilingHeight: "Average Ceiling Height",
  rooms: "Rooms",
  sqFtOverGarage: "Square Feet Over Garage",
  sqFtOverPorchOrPatio: "Square Feet Over Porch or Patio",
  targetCFM50: "Target CFM50",
  targetCFM25: "Target CFM25",
};

export default HomeDetails;
