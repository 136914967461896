import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Select from "react-select";
import { convertEmptyStringsToNull } from "../../utils/formUtils";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import FormHeader from "../common/forms/FormHeader";
import { WithSpacesBetween } from "../common/list-wrappers";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";

import Button, { GrayButton, SubmitButton } from "../common/Buttons";
import { selectContactsOptions, selectCustomerOptions } from "../../state/slices/customerSlice";
import { formatDateToString, formatInputDate } from "../../utils/formatDate";
import HomeService from "../../services/home";
import InspectionService, { inspectionPassFailOptions } from "../../services/inspection";
import MultipleFileUploader from "./MultipleFileUploader";
import FilteredContactsOptions from "./FilteredContactsOptions";
import { USAStates } from "../../utils/constants";
import Multiselect from "../common/forms/Multiselect";
import { inspectionOptions } from "../../services/inspection";
import { reportTypeOptions } from "../../services/report-options";
import HeadingWithTabsAndActions from "../common/HeadingWithTabsAndActions";
import { flashSuccessNotification } from "../../state/slices/notificationSlice";
import FormikFieldWithLabelAndError from "../common/FormikFieldWithLabel";
import Modal from "../common/Modal";

export default function HomeFormModal({ home = {}, refreshHomes, isOpen, onClose }) {
  const customerOptions = useSelector(selectCustomerOptions);
  const contactsOptions = useSelector(selectContactsOptions);
  const userOptions = useSelector(state => state.user.userOptions);
  const cityFormOptions = useSelector(state => state.cityForm.cityFormOptions);
  const [isModified, setIsModified] = useState(false);

  const isTestingOnly = home.testingType === "testingOnly";

  let tabs;
  if (home.id && isTestingOnly) tabs = ["General", "Modeling", "Inspections"];
  else if (home.id) tabs = ["General", "Inspections"];
  else tabs = ["General"];
  const [tab, setTab] = React.useState(tabs[0]);

  function handleTabChange(newTab) {
    if (!isModified) return setTab(newTab);
    else if (window.confirm("You have unsaved changes. Are you sure you want to leave?")) {
      setTab(newTab);
      setIsModified(false);
    }
  }

  const handleCloseHomeFormModal = ignoreModifiedWarning => {
    if (!isModified || ignoreModifiedWarning) {
      onClose(false);
      setTab(tabs[0]);
    }
    // prompt user if they want to cancel only if there are unsaved changes
    else if (window.confirm("You have unsaved changes.\nAre you sure you want to cancel?")) {
      onClose(false);
      setTab(tabs[0]);
    }
  };

  return (
    <Modal modalShowing={isOpen} closeModal={handleCloseHomeFormModal}>
      <HeadingWithTabsAndActions tabs={tabs} currentTab={tab} onChange={handleTabChange} />
      {tab === "General" && (
        <GeneralHomeForm
          home={home}
          handleModified={setIsModified}
          customerOptions={customerOptions}
          contactsOptions={contactsOptions}
          cityFormOptions={cityFormOptions}
          userOptions={userOptions}
          closeModal={handleCloseHomeFormModal}
        />
      )}
      {tab === "Modeling" && (
        <HomeModelingForm
          home={home}
          handleModified={() => setIsModified(true)}
          closeModal={handleCloseHomeFormModal}
        />
      )}
      {tab === "Inspections" && (
        <HomeInspectionsSchedulingForm
          home={home}
          onModified={setIsModified}
          refreshHomes={refreshHomes}
          userOptions={userOptions}
        />
      )}
    </Modal>
  );
}

export const reactSelectStyles = {
  control: provided => ({
    ...provided,
    borderRadius: "0.5rem",
    height: "2.7rem",
    borderColor: "rgb(209,213,219)",
  }),
  placeholder: provided => ({
    ...provided,
    color: "rgb(156, 163, 175)",
    fontSize: "0.875rem",
  }),
};

export function GeneralHomeForm({
  home,
  handleModified,
  closeModal,
  customerOptions,
  contactsOptions,
  cityFormOptions,
  userOptions,
}) {
  const dispatch = useDispatch();

  const initialValues = {
    id: home.id || "",
    CustomerId: `${home?.CustomerId || ""}`,
    modelerId: home.modelerId || "",
    homeRequestorPOCId: home.homeRequestorPOCId || "",
    homeInspectionPOCId: home.homeInspectionPOCId || "",
    dateReceived: formatDateToString(home.dateReceived) || "",
    notes: home.notes || "",
    testingType: home.testingType || "",
    pathOfCompliance: home.pathOfCompliance || "",
    FloorPlanId: home.FloorPlanId || "",
    CityFormId: home.CityFormId || "",
    streetAddress: home.streetAddress || "",
    lotNumber: home.lotNumber || "",
    city: home.city || "",
    state: home.state || "",
    zip: home.zip || "",
    county: home.county || "",
    reportsRequired: home.reportsRequired || [],
    projectedReports: home.projectedReports || [],
    attachments: home.attachments || [],
  };

  // Validation rules
  const HomeSchema = Yup.object().shape({
    // CustomerId: Yup.string().required("Required"),
    // PlantId: Yup.string().required("Required"),
    // ParkId: Yup.string(),
    // RetailerId: Yup.string(),
    // schedulerNotes: Yup.string(),
    // testingType: Yup.string().required("Required"),
    // streetAddress: Yup.string().required("Required"),
    // city: Yup.string().required("Required"),
    // state: Yup.string().required("Required"),
    // lotNumber: Yup.string(),
    // zip: Yup.string(),
    // county: Yup.string(),
    // dateReceived: Yup.date().required("Required"),
  });

  const handleSubmit = async home => {
    const cleanedHome = convertEmptyStringsToNull(home);
    await HomeService.upsert(cleanedHome);
    handleModified(false);
    closeModal(true); // close the modal without the warning (handleModified state updates asynchronusly)
    dispatch(flashSuccessNotification("Home updated!"));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={HomeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values, handleChange }) => {
        const handleInputChange = event => {
          handleChange(event);
          handleModified(true);
        };

        const shouldShowPathOfCompliance = values.testingType !== "hersOnly";

        return (
          <>
            <FormHeader className="mt-4">{initialValues.id ? "Edit Home" : "New Home"}</FormHeader>
            <Form>
              <WithSpacesBetween>
                <div className="grid grid-cols-2 gap-2">
                  <div className=" my-3 pr-4">
                    <label
                      htmlFor="CustomerId"
                      id="CustomerId-label"
                      className="block text-sm text-gray-900 mb-2"
                    >
                      Builder
                    </label>
                    <Select
                      options={customerOptions}
                      isSearchable={true}
                      isClearable={true}
                      onChange={e => {
                        handleInputChange({
                          target: { name: "CustomerId", value: e?.value?.toString() || "" },
                        });
                      }}
                      // eslint-disable-next-line eqeqeq
                      value={customerOptions.find(c => c.value == values.CustomerId) || ""}
                      id="CustomerId"
                      name="CustomerId"
                      placeholder="Select Builder"
                      styles={reactSelectStyles}
                    />
                  </div>

                  <FormikFieldWithLabel
                    name="dateReceived"
                    label="Date Received"
                    className="col-span-1"
                    type="date"
                    onChange={handleInputChange}
                    error={touched.dateReceived && errors.dateReceived}
                  />
                  <FormikFieldWithLabel
                    name="modelerId"
                    label="Modeler"
                    as="select"
                    onChange={handleInputChange}
                    error={touched.modelerId && errors.modelerId}
                  >
                    <option value="">---</option>
                    {userOptions.map(user => (
                      <option value={user.id} key={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </FormikFieldWithLabel>

                  <FormikFieldWithLabel
                    name="homeRequestorPOCId"
                    label="Builder Requestor"
                    as="select"
                    onChange={handleInputChange}
                    error={touched.homeRequestorPOCId && errors.homeRequestorPOCId}
                  >
                    <option value="">---</option>
                    <FilteredContactsOptions
                      contactsOptions={contactsOptions.requestors}
                      customerId={values.CustomerId}
                    />
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="homeInspectionPOCId"
                    label="Inspection POC"
                    as="select"
                    onChange={handleInputChange}
                    error={touched.homeInspectionPOCId && errors.homeInspectionPOCId}
                  >
                    <option value="">---</option>
                    <FilteredContactsOptions
                      contactsOptions={contactsOptions.inspectionPOCs}
                      customerId={values.CustomerId}
                    />
                  </FormikFieldWithLabel>

                  <FormikFieldWithLabel
                    className="col-span-2"
                    name="notes"
                    label="Notes"
                    as="textarea"
                    onChange={handleInputChange}
                    fieldClass="h-24 resize-y"
                  />
                  <FormikFieldWithLabel
                    as="select"
                    name="testingType"
                    label="Testing Type"
                    onChange={handleInputChange}
                    error={touched.testingType && errors.testingType}
                  >
                    <option value="">---</option>
                    <option value="testingOnly">Testing Only</option>
                    <option value="hersOnly">HERS Only</option>
                    <option value="fullEnergyCompliance">Full Energy Compliance</option>
                    <option value="fullEnergyComplianceAndEnergyStar">
                      Full Energy Compliance and Energy Star
                    </option>
                  </FormikFieldWithLabel>
                  {shouldShowPathOfCompliance && (
                    <FormikFieldWithLabel
                      as="select"
                      name="pathOfCompliance"
                      label="Compliance Path"
                      onChange={handleInputChange}
                      error={touched.pathOfCompliance && errors.pathOfCompliance}
                    >
                      <option value="">---</option>
                      <option value="2018IECC">2018 IECC</option>
                      <option value="2021IECC">2021 IECC</option>
                      <option value="2024IECC">2024 IECC</option>
                      <option value="EnergyStar">Energy Star</option>
                    </FormikFieldWithLabel>
                  )}
                  {/* <FormikFieldWithLabel
                  as="select"
                  name="FloorPlanId"
                  label="Floor Plan"
                  error={touched.FloorPlanId && errors.FloorPlanId}
                >
                  <option value="">---</option>
                  <FilteredFloorPlanOptions
                    floorPlanOptions={floorPlanOptions}
                    customerId={values.CustomerId}
                  />
                </FormikFieldWithLabel> */}
                  <div>
                    <label
                      htmlFor="reportsRequired"
                      className="block text-sm text-gray-900 mt-3 mb-2"
                    >
                      Reports Required
                    </label>
                    <Multiselect
                      name="reportsRequired"
                      placeholder="Select reports"
                      searchable={false}
                      options={Object.keys(reportTypeOptions).map(o => ({
                        label: reportTypeOptions[o],
                        id: o,
                      }))}
                      value={values.reportsRequired}
                      onChange={e =>
                        handleInputChange({
                          target: { name: "reportsRequired", value: e.target.value },
                        })
                      }
                    />
                  </div>
                  <FormikFieldWithLabel
                    name="streetAddress"
                    label="Street Address"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.streetAddress && errors.streetAddress}
                  />
                  <FormikFieldWithLabel
                    name="lotNumber"
                    label="Lot #"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.lotNumber && errors.lotNumber}
                  />
                  <FormikFieldWithLabel
                    name="city"
                    label="City"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.city && errors.city}
                  />
                  <FormikFieldWithLabel
                    as="select"
                    name="state"
                    label="State"
                    onChange={handleInputChange}
                    error={touched.state && errors.state}
                  >
                    <option value="">---</option>
                    {USAStates.map(state => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="zip"
                    label="Zip Code"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.zip && errors.zip}
                  />
                  <FormikFieldWithLabel
                    name="county"
                    label="County"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.county && errors.county}
                  />
                  <FormikFieldWithLabel
                    as="select"
                    name="CityFormId"
                    label="City Form"
                    onChange={handleInputChange}
                    error={touched.CityFormId && errors.CityFormId}
                  >
                    <option value="">---</option>
                    {cityFormOptions.map(cityForm => (
                      <option value={cityForm.id} key={cityForm.id}>
                        {cityForm.city}, {cityForm.state}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <br />
                  <div className="col-span-2">
                    <MultipleFileUploader
                      title="Projected Reports"
                      files={values.projectedReports}
                      onUpload={value =>
                        handleInputChange({ target: { name: "projectedReports", value } })
                      }
                    />
                  </div>
                  <div className="col-span-2">
                    <MultipleFileUploader
                      title="Attachments"
                      files={values.attachments}
                      onUpload={value =>
                        handleInputChange({ target: { name: "attachments", value } })
                      }
                    />
                  </div>
                </div>
              </WithSpacesBetween>

              <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
                <SubmitButton />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export function HomeModelingForm({ home, handleModified, closeModal }) {
  const dispatch = useDispatch();

  const initialValues = {
    id: home.id || "",
    conditionedArea: home.conditionedArea || "",
    conditionedVolume: home.conditionedVolume || "",
    targetCFM25: home.targetCFM25 || "",
    targetCFM50: home.targetCFM50 || "",
  };

  // Validation rules
  const HomeSchema = Yup.object().shape({
    conditionedArea: Yup.number().typeError("Must be a number").required("Required"),
    conditionedVolume: Yup.number().typeError("Must be a number").required("Required"),
    targetCFM25: Yup.number().typeError("Must be a number").required("Required"),
    targetCFM50: Yup.number().typeError("Must be a number").required("Required"),
  });

  const handleSubmit = async home => {
    const cleanedHome = convertEmptyStringsToNull(home);
    await HomeService.upsert(cleanedHome);
    closeModal(true); // close the modal without the warning (handleModified state updates asynchronusly)
    dispatch(flashSuccessNotification("Home model updated!"));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={HomeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values, handleChange }) => {
        const handleInputChange = event => {
          handleChange(event);
          handleModified(true);
        };

        return (
          <>
            <FormHeader className="mt-4">Edit Home Energy Model</FormHeader>
            <Form>
              <WithSpacesBetween>
                <div className="grid grid-cols-2 gap-2">
                  <FormikFieldWithLabel
                    name="conditionedArea"
                    label="Conditioned Area"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.conditionedArea && errors.conditionedArea}
                  />
                  <FormikFieldWithLabel
                    name="conditionedVolume"
                    label="Conditioned Volume"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.conditionedVolume && errors.conditionedVolume}
                  />
                  <FormikFieldWithLabel
                    name="targetCFM25"
                    label="Target CFM DT"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.targetCFM25 && errors.targetCFM25}
                  />
                  <FormikFieldWithLabel
                    name="targetCFM50"
                    label="Target CFM for Air Inf"
                    className="col-span-1"
                    onChange={handleInputChange}
                    error={touched.targetCFM50 && errors.targetCFM50}
                  />
                </div>
              </WithSpacesBetween>

              <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
                <SubmitButton />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

const parsedInspectionOptions = Object.keys(inspectionOptions).map(o => ({
  value: o,
  label: inspectionOptions[o],
}));

function getRequiredInspectionsText(requiredInspections) {
  return requiredInspections.map(ri => inspectionOptions[ri]).join(", ");
}

export function HomeInspectionsSchedulingForm({ home, onModified, refreshHomes, userOptions }) {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = React.useState(false);

  const [modifiedInspections, setModifiedInspections] = useState([]);

  const handleModified = id => isModified => {
    const newModifiedInspections = modifiedInspections.filter(i => i.id !== id);
    if (isModified) newModifiedInspections.push({ id });
    setModifiedInspections(newModifiedInspections);
  };

  useEffect(() => {
    if (modifiedInspections.length > 0) onModified(true);
    else onModified(false);
  }, [modifiedInspections, onModified]);

  const formattedRequiredInspections = getRequiredInspectionsText(home.requiredInspections || []);

  return (
    <>
      <FormHeader className="mt-4">Edit Inspections</FormHeader>
      {home.requiredInspections?.length > 0 && (
        <p className="my-4 text-blue-600">Required inspections: {formattedRequiredInspections}</p>
      )}
      {home?.Inspections?.map(inspection => (
        <InspectionItemOrForm
          inspection={inspection}
          userOptions={userOptions}
          key={inspection.id}
          onModified={handleModified(inspection.id)}
          refreshHomes={refreshHomes}
        />
      ))}
      {isAdding ? (
        <IndividualInspectionForm
          inspection={{ HomeId: home.id }}
          onCancel={() => {
            handleModified("new")(false);
            setIsAdding(false);
          }}
          onModified={handleModified("new")}
          onSubmit={() => {
            setIsAdding(false);
            handleModified("new")(false);
            dispatch(flashSuccessNotification("Inspection added!"));
            refreshHomes();
          }}
        />
      ) : (
        <div className="px-4 py-3 mt-auto text-left sm:px-6 flex justify-start">
          <Button
            className="w-48"
            onClick={() => {
              setIsAdding(true);
              handleModified("new")(true);
            }}
          >
            Add Inspection
          </Button>
        </div>
      )}
    </>
  );
}

function InspectionItemOrForm({ inspection, onModified, refreshHomes, userOptions }) {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = React.useState(false);
  const handleCancel = () => {
    onModified(false);
    setIsEditing(false);
  };
  const handleSubmit = async () => {
    await refreshHomes();
    setIsEditing(false);
    onModified(false);
    dispatch(flashSuccessNotification("Inspection updated!"));
  };
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this inspection?")) {
      await InspectionService.delete(inspection.id);
      await refreshHomes();
      dispatch(flashSuccessNotification("Inspection deleted!"));
    }
  };

  const getUserName = id => {
    const user = userOptions.find(u => u.id === id);
    return user ? user.name : "";
  };

  if (isEditing)
    return (
      <IndividualInspectionForm
        inspection={inspection}
        onModified={onModified}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    );
  else
    return (
      <div key={inspection.id} className=" border-gray-200 grid rounded border p-4 mt-3 gap-4">
        <p>Inspection Type: {inspectionOptions[inspection.inspectionType]}</p>
        <p>
          Status:{" "}
          {inspection.status === "completed"
            ? inspectionPassFailOptions[inspection.passFail]
            : inspection.status}
        </p>
        {inspection.status === "completed" ? (
          <p>Completed on: {formatDateToString(inspection.inspectionDate)}</p>
        ) : inspection.status === "requested" ? (
          <p>Requested for: {formatDateToString(inspection.requestedFor)}</p>
        ) : (
          <p>Scheduled for: {formatDateToString(inspection.scheduledFor)}</p>
        )}
        {inspection.UserId && <p>Inspector: {getUserName(inspection.UserId)}</p>}
        {(inspection.status === "scheduled" || inspection.status === "requested") && !isEditing && (
          <div className="w-full gap-4">
            <button
              type="button"
              className="mr-2 text-sm text-red-500 hover:bg-gray-100 rounded-sm"
              onClick={handleDelete}
              data-testid={`delete-inspection-${inspection.id}`}
            >
              <TrashIcon className="h-6 w-6 m-2" />
            </button>
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="text-sm text-blue-500 hover:bg-gray-100 rounded-sm"
              data-testid={`edit-inspection-${inspection.id}`}
            >
              <PencilIcon className="h-6 w-6 m-2" />
            </button>
          </div>
        )}
      </div>
    );
}

function IndividualInspectionForm({ inspection, onModified, onCancel, onSubmit }) {
  const userOptions = useSelector(state => state.user.userOptions);

  const handleSubmit = async values => {
    // update scheduledFor to be a date object at 12PM in current user's timezone
    // otherwise it gets stored as 12AM UTC and then displays as the previous day
    const isDateString = typeof values.scheduledFor === "string";
    const hasTimezone = values.scheduledFor?.includes("T"); // edge case
    if (isDateString && !hasTimezone)
      values.scheduledFor = new Date(`${values.scheduledFor}T12:00:00`);

    // Submitting the form on a requested inspection should automatically switch the status to scheduled
    if (inspection.status === "requested") {
      values.status = "scheduled";
    }

    if (values.id) await InspectionService.update(values);
    else await InspectionService.create(values);
    onModified(false);
    onSubmit();
  };

  const initialValues = { ...inspection };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange }) => {
        function handleInputChange(e) {
          onModified(true);
          handleChange(e);
        }

        return (
          <Form className=" border-gray-200 grid grid-cols-2 rounded border p-4 mt-3 gap-4">
            <FormikFieldWithLabelAndError
              name="scheduledFor"
              label="Inspection Date"
              type="date"
              value={formatInputDate(values.scheduledFor)}
              onChange={handleInputChange}
              className="w-full"
            />
            <FormikFieldWithLabelAndError
              name="inspectionType"
              label="Inspection Type"
              type="select"
              as="select"
              onChange={handleInputChange}
              value={values.inspectionType || ""}
              className="w-full"
            >
              <option value="">---</option>
              {parsedInspectionOptions.map(option => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
            <FormikFieldWithLabelAndError
              name="UserId"
              label="Inspector"
              type="select"
              as="select"
              onChange={handleInputChange}
              value={values.UserId || ""}
              className="w-full"
            >
              <option value="">---</option>
              {userOptions.map(option => (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
            <FormikFieldWithLabelAndError
              name="schedulerNotes"
              label="Scheduler Notes"
              type="textarea"
              as="textarea"
              onChange={handleInputChange}
              value={values.schedulerNotes || ""}
              className="col-span-2 w-full"
            />
            <FormikFieldWithLabelAndError
              name="requestorNotes"
              label="Requestor Notes"
              type="textarea"
              as="textarea"
              onChange={handleInputChange}
              value={values.requestorNotes || ""}
              className="col-span-2 w-full"
              disabled
            />
            <div className="col-span-2 flex flex-row-reverse gap-4">
              <SubmitButton>
                {inspection.status === "requested" ? "Schedule inspection" : "Submit"}
              </SubmitButton>
              <GrayButton onClick={onCancel}>Cancel</GrayButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
