import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import { NavLink, useHistory } from "react-router-dom";
import { ClipboardListIcon, GlobeIcon, HomeIcon, UsersIcon } from "@heroicons/react/solid";
import { MenuIcon } from "@heroicons/react/outline";
import { logoutAsync } from "../../state/slices/authSlice";
import Logo from "./Logo";
import { AllModalForms } from "./AllModalForms";
import { getIsOnBuilderWebsite } from "../../utils/isBuilderWebsite";

function NavbarLink({ to, icon, title }) {
  const IconComponent = icon;
  return (
    <NavLink
      to={to}
      activeClassName="bg-gray-900 text-white hover:bg-gray-900"
      className="hover:bg-gray-700 text-lg text-white hover:text-white px-3 py-2 rounded-md font-medium"
    >
      <span className="inline-flex justify-around items-center">
        <IconComponent className="w-5 h-5 mx-1" />
        <span className="text-md">{title}</span>
      </span>
    </NavLink>
  );
}

export function NavbarLinks() {
  return (
    <>
      <NavbarLink to="/homes" icon={HomeIcon} title="Homes" />
      <NavbarLink to="/inspections" icon={GlobeIcon} title="Inspections" />
      <NavbarLink to="/builders" icon={ClipboardListIcon} title="Builders" />
      {/* <NavbarLink to="/floor-plans" icon={ClipboardListIcon} title="Floor Plans" /> */}
      <NavbarLink to="/city-forms" icon={ClipboardListIcon} title="City Forms" />
      {/* <NavbarLink to="/plants" icon={OfficeBuildingIcon} title="Plants" /> */}
      <NavbarLink to="/users" icon={UsersIcon} title="Users" />
    </>
  );
}

const Navbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector(state => state.auth);

  const handleLogout = () => {
    dispatch(logoutAsync()).then(() => {
      const isOnBuilderSite = getIsOnBuilderWebsite();
      const path = isOnBuilderSite ? "/auth/builder/signin" : "/auth/signin";
      history.push(path);
    });
  };

  return (
    <nav className="bg-gray-800 w-full h-16">
      <div className="max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between md:h-16 h-full">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Logo />
            </div>
            {/* Retailers don't see any links, regular users see links to all modules */}
            {currentUser?.role === "user" ? (
              <div className="hidden xl:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <NavbarLinks />
                </div>
              </div>
            ) : null}
          </div>
          <div className="hidden xl:block">
            <div className="ml-4 flex items-center md:ml-6">
              <ProfileDropdown handleLogout={handleLogout} />
            </div>
          </div>

          <MobileMenu handleLogout={handleLogout} />
        </div>
      </div>

      <AllModalForms />
    </nav>
  );
};

function ProfileDropdown({ handleLogout }) {
  const profileMenuRef = useRef(null);
  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false);
  const { currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = event => {
    const profileMenu = profileMenuRef?.current;
    const isClickOutsideProfileMenu = profileMenu && !profileMenu.contains(event.target);
    if (isClickOutsideProfileMenu) setProfileDropdownIsOpen(false);
  };

  return (
    <div className="ml-3 relative">
      <div>
        <button
          className="flex text-md font-semibold text-xl text-white border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
          onClick={() => setProfileDropdownIsOpen(!profileDropdownIsOpen)}
        >
          {currentUser && currentUser.avatar ? (
            <img className="h-8 w-8 rounded-full" src={currentUser.avatar} alt="User's avatar" />
          ) : (
            <span className="h-8 w-8 rounded-full">{currentUser ? currentUser.name[0] : "U"}</span>
          )}
        </button>
      </div>

      <Transition
        show={profileDropdownIsOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className="d-none origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
          ref={profileMenuRef}
        >
          <div
            className="py-1 rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <button
              className="w-full block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
              role="menuitem"
              onClick={handleLogout}
            >
              Log out
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
}

function MobileMenu({ handleLogout }) {
  const navMenuRef = useRef(null);
  const [navMenu, setNavMenu] = useState(false);
  const { currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = event => {
    const mobileMenu = navMenuRef?.current;
    const isClickOutsideMobileMenu = mobileMenu && !mobileMenu.contains(event.target);
    if (isClickOutsideMobileMenu) setNavMenu(false);
  };

  return (
    <div className="xl:hidden">
      <button
        type="button"
        className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        aria-controls="mobile-menu"
        aria-expanded="false"
        aria-haspopup="true"
        onClick={() => setNavMenu(!navMenu)}
      >
        <span className="sr-only">Open main menu</span>
        <MenuIcon className="block h-6 w-6" />
      </button>
      <Transition
        show={navMenu}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          ref={navMenuRef}
          className="xl:hidden bg-gray-600 absolute top-16 left-4 right-4 z-10"
          id="mobile-menu"
          onClick={e => setNavMenu(!navMenu)}
        >
          {/* Retailers don't see any links, regular users see links to all modules */}
          {currentUser?.role === "user" ? (
            <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col sm:px-3">
              <NavbarLinks />
            </div>
          ) : null}
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                {currentUser && currentUser.avatar ? (
                  <img
                    className="h-8 w-8 rounded-full"
                    src={currentUser.avatar}
                    alt="User's avatar"
                  />
                ) : (
                  <span className="h-8 w-8 rounded-full text-white">
                    {currentUser ? currentUser.name[0] : "U"}
                  </span>
                )}
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white">
                  {currentUser && currentUser.name}
                </div>
                <div className="text-sm font-medium leading-none text-gray-400">
                  {currentUser && currentUser.email}
                </div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <button
                onClick={handleLogout}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default Navbar;
