import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../slices/authSlice";
import UserSlice from "../slices/userSlice";
import notificationSlice from "../slices/notificationSlice";
import CustomerSlice from "../slices/customerSlice";
import FrontEndSlice from "../slices/frontend";
import homeSlice from "../slices/homeSlice";
import tripSlice from "../slices/tripSlice";
import floorPlanSlice from "../slices/floorPlanSlice";
import cityFormSlice from "../slices/cityFormSlice";

export const reducer = {
  frontend: FrontEndSlice,
  auth: AuthSlice,
  user: UserSlice,
  homes: homeSlice,
  notification: notificationSlice,
  customer: CustomerSlice,
  floorPlan: floorPlanSlice,
  cityForm: cityFormSlice,
  trip: tripSlice,
};

export default configureStore({
  reducer,
});

// This function is mostly intended for use in tests
export const createStoreWithPreloadedState = preloadedState =>
  configureStore({
    reducer,
    preloadedState,
  });
