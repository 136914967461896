import React, { useEffect, useState } from "react";
import moment from "moment";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/solid";

/**
 * Renders a table with pagination controls.
 * Use this table when the parent component fetches all data upfront.
 */
export default function TablePaginatedAllItemsUpfront({
  columns,
  items,
  pageSize = 25,
  className = "",
  onRowClick = () => {},
}) {
  const maxPage = Math.ceil(items.length / pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(items || []);
  const paginationNumbers = getPaginationNumbers(currentPage, maxPage);

  const isOnFirstPage = currentPage === 1;
  const isOnLastPage = currentPage === maxPage;

  useEffect(() => {
    // when currentPage changes, change the subset of items we render
    setData(items.slice((currentPage - 1) * pageSize, currentPage * pageSize));

    // if items are changed, make sure we don't stay on a page that doesn't exist
    // e.g. start with 27 items and move to page 2, then re-render with 20 items
    const totalPages = Math.ceil(items.length / pageSize);
    if (currentPage > totalPages) setCurrentPage(1); // jump to first page is good enough for current use cases
  }, [pageSize, items, currentPage]);

  return (
    <div className={className + ``}>
      <div className={`flex flex-col overflow-auto h-full`}>
        <div className="py-2 w-full align-middle overflow-y-auto sm:px-6 lg:px-8">
          <Table columns={columns} onRowClick={onRowClick} rows={data} />
          <PaginationNav
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            paginationNumbers={paginationNumbers}
            isOnFirstPage={isOnFirstPage}
            isOnLastPage={isOnLastPage}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * Renders a table with pagination controls.
 * Use this table when the parent component re-fetches data in response to page changes.
 */
export function TableWithPagination({
  columns,
  items,
  setPage,
  maxPage,
  currentPage,
  onRowClick,
  className = "",
}) {
  const [data, setData] = useState(items || []);

  const paginationNumbers = getPaginationNumbers(currentPage, maxPage);
  const isOnFirstPage = currentPage === 1;
  const isOnLastPage = currentPage === maxPage;

  useEffect(() => {
    if (!items?.length) setData([]);
    else setData(items);
  }, [items]);

  return (
    <div className={className + ``}>
      <div className={`flex flex-col overflow-auto h-full`}>
        <div className="py-2 w-full align-middle overflow-y-auto sm:px-6 lg:px-8">
          <Table columns={columns} onRowClick={onRowClick} rows={data} />
          <PaginationNav
            currentPage={currentPage}
            setCurrentPage={setPage}
            paginationNumbers={paginationNumbers}
            isOnFirstPage={isOnFirstPage}
            isOnLastPage={isOnLastPage}
          />
        </div>
      </div>
    </div>
  );
}

export function getPaginationNumbers(currentPage, pageCount) {
  const delta = 2;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;

  const allPages = Array.from({ length: pageCount }, (v, k) => k + 1);
  const closestPages = allPages.filter(i => i && i >= left && i < right);

  if (closestPages.length < 3) return closestPages;

  // Add ellipsis and last page if needed
  const lastPage = closestPages[closestPages.length - 1];
  if (lastPage < pageCount) {
    if (lastPage !== pageCount - 1) closestPages.push("...");
    closestPages.push(pageCount);
  }
  return closestPages;
}

// see more: https://gridjs.io/docs/config/className
export const gridjsClasses = {
  table:
    "text-left w-full divide-y divide-gray-200 overflow-hidden max-h-full shadow border-b border-gray-200 sm:rounded-lg",
  tbody: "bg-white divide-y divide-gray-200 cursor-pointer",
  thead: "bg-gray-50",
  tr: "hover:bg-gray-100",
  th: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
  td: "px-6 py-3 truncate text-sm text-gray-900",
  pagination: "mt-2",
  paginationButton:
    "border-transparent border-t-2 text-gray-500 hover:text-gray-700 outline-none focus:outline-none hover:border-gray-300 py-4 px-4 text-sm font-medium",
  paginationButtonPrev: "mr-auto hover:text-gray-700",
  paginationButtonNext: "ml-auto hover:text-gray-700",
  paginationButtonCurrent: "hover:text-gray-700 outline-none focus:outline-none",
};

function Table({ columns, rows, onRowClick }) {
  return (
    <div className="shadow overflow-y-auto border-b border-gray-200 sm:rounded-lg">
      <table className="overflow-y-auto max-h-full w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column, i) => (
              <ColumnHeader key={(column.dataField ?? "column") + i} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.from(rows).map((row, index) => {
            return (
              <tr
                key={"rowIndex" + index}
                className={`hover:bg-gray-100 ${onRowClick ? "cursor-pointer" : ""}`}
                onClick={() => (onRowClick ? onRowClick(row) : null)}
              >
                {columns.map((column, i) => (
                  <TableDataCell
                    key={(column.dataField ?? "column") + i}
                    column={column}
                    row={row}
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function ColumnHeader({ column }) {
  const extraClasses = column.class || "";
  const text = /action/i.test(column.type)
    ? ""
    : /total/i.test(column.type)
    ? "Total"
    : column.caption ?? column.dataField ?? column["data-field"];

  return (
    <th
      scope="col"
      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${extraClasses}`}
    >
      {text}
    </th>
  );
}

function TableDataCell({ column, row }) {
  if (column?.cellDisplay) return <td className="px-6 py-2 truncate">{column.cellDisplay(row)}</td>;

  const isDate = /date/i.test(column.dataType);
  const value = row[column.dataField];
  return (
    <td className="px-6 py-2 truncate">
      <div className={"text-sm text-gray-900 " + column.class ?? ""}>
        {isDate ? moment(value).format("MM/DD/YYYY") : value}
      </div>
    </td>
  );
}

function PaginationNav({
  isOnFirstPage,
  isOnLastPage,
  paginationNumbers,
  currentPage,
  setCurrentPage,
}) {
  return (
    <nav className="border-t border-gray-200 px-4 flex items-center mt-2 sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {!isOnFirstPage && <PreviousPageButton onClick={() => setCurrentPage(currentPage - 1)} />}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {paginationNumbers.length > 1 &&
          paginationNumbers.map(page => (
            <NumberedPageButton
              key={page}
              page={page}
              onClick={page => setCurrentPage(page)}
              isCurrentPage={page === currentPage}
            />
          ))}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {!isOnLastPage && <NextPageButton onClick={() => setCurrentPage(currentPage + 1)} />}
      </div>
    </nav>
  );
}

function NextPageButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      href="#"
      className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
    >
      Next
      <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" />
    </button>
  );
}

function PreviousPageButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      href="#"
      className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
    >
      <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" />
      Previous
    </button>
  );
}

function NumberedPageButton({ page, onClick, isCurrentPage }) {
  function handleClick() {
    if (isNaN(parseInt(page))) return; // don't do anything if it's an ellipsis
    onClick(page);
  }

  return (
    <button
      onClick={handleClick}
      className={`order-transparent ${
        isCurrentPage ? "border-t-2" : ""
      } text-gray-500 hover:text-gray-700 outline-none focus:outline-none hover:border-gray-300  pt-4 px-4 inline-flex items-center text-sm font-medium`}
    >
      {page}
    </button>
  );
}
