import axios from "axios";

export const upsert = async cityForm => {
  const route = "/api/city-form";
  const { data } = await axios.post(route, cityForm);
  return data;
};

export const getAll = async () => {
  const { data } = await axios.get("/api/city-forms");
  return data;
};

export const getAllOptions = async () => {
  const { data } = await axios.get("/api/city-form/options");
  return data;
};

export const remove = async id => {
  const route = `/api/city-form/${id}`;
  const { data } = await axios.delete(route);
  return data;
};

export async function validateCityStateUnique({ city, state }) {
  const route = "/api/city-form/validate";
  const response = await axios.get(route, { params: { city, state } });
  return response.data;
}
