import React from "react";
import { Field } from "formik";
import Tooltip from "@mui/material/Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { ErrorLabel } from "./Field";

export const MyTooltip = ({ text }) => (
  // TODO: make text a bit bigger https://mui.com/material-ui/react-tooltip/#customization
  <Tooltip title={text} enterTouchDelay={0}>
    {/* The icon must be wrapped in a button otherwise the tooltip won't show up */}
    <button type="button" className="cursor-default">
      <QuestionMarkCircleIcon className="h-5 w-5" />
    </button>
  </Tooltip>
);

// Source:https://formik.org/docs/api/field

export default function FormikFieldWithLabelAndError({
  name,
  label,
  type,
  children,
  error,
  disabled,
  tooltip,
  className = "",
  fieldClass = "",
  ...rest
}) {
  const isCheckbox = type === "checkbox";

  const flexContainerClass = isCheckbox
    ? "flex-row-reverse justify-end items-center my-2"
    : "flex-col my-3 pr-4";
  const labelClass = isCheckbox ? "ml-2" : "mb-2";
  const widthClass = isCheckbox ? "" : "w-full";
  const borderClass = error ? "border-red-500" : "border-gray-300";
  const disabledClass = disabled ? "text-gray-600 bg-gray-200 cursor-not-allowed" : "";

  return (
    <div className={`flex ${flexContainerClass} ${className}`}>
      <label htmlFor={name} className={`${labelClass} text-sm text-gray-900`}>
        <span className="flex gap-2">
          {label}
          {tooltip && <MyTooltip text={tooltip} />}
        </span>
      </label>
      <Field
        id={name}
        name={name}
        type={type}
        className={`resize-none bg-white block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${widthClass} ${borderClass} ${disabledClass} ${fieldClass}`}
        disabled={disabled}
        data-testid={"input-field-" + name}
        {...rest}
      >
        {children}
      </Field>
      {error ? <ErrorLabel error={error} name={name} /> : null}
    </div>
  );
}
