export function createQueryString(obj) {
  const searchKeys = Object.keys(obj);
  if (searchKeys.length === 0) return "";
  const searchParams = new URLSearchParams();
  searchKeys.forEach(key => {
    const val = obj[key];
    if (val === null || val === undefined || val === "" || val === false) return;
    searchParams.append(key, val);
  });
  return "?" + searchParams.toString();
}

export function updateQueryString(queryString, params) {
  if (!params) return queryString || "";
  const urlParams = new URLSearchParams(queryString);

  for (const [key, value] of Object.entries(params)) {
    if (value === null || value === undefined || value === "" || value === false)
      urlParams.delete(key);
    else urlParams.set(key, value);
  }

  return urlParams.toString() ? `?${urlParams.toString()}` : "";
}

export function getPageFromSearchParams(searchString) {
  const pageStr = getParamFromSearchParams(searchString, "page");
  return parseInt(pageStr) || 1;
}

export function getParamFromSearchParams(searchString, param) {
  // searchString expected to be like "?page=1"
  const searchParams = new URLSearchParams(searchString);
  const value = searchParams.get(param);
  return value;
}

export function getStartingFilters(searchQuery) {
  const query = new URLSearchParams(searchQuery);
  const filters = {};
  for (const [key, value] of query.entries()) {
    if (key === "page") continue;
    filters[key] = value;
  }
  return filters;
}
