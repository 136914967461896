import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, XIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { resetNotification } from "../../state/slices/notificationSlice";

const Notification = () => {
  const dispatch = useDispatch(false);
  const { showing, type, details } = useSelector(state => state.notification);

  return (
    <Transition
      show={showing}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      as="div"
      className="fixed z-10 top-6 right-6 w-80 p-4 bg-white rounded-lg"
    >
      <div className="flex items-start">
        <CheckCircleIcon
          className={`h-6 w-6 ${type === "error" ? "text-red-700" : "text-green-700"}`}
        />
        <div className="ml-3 flex-1 pt-0.5">
          <p
            className={`text-sm leading-5 font-medium ${
              type === "error" ? "text-red-700" : "text-green-700"
            }`}
          >
            {type === "error" ? "Error" : "Success"}
          </p>
          <p
            className={`mt-1 text-sm leading-5 ${
              type === "error" ? "text-red-700" : "text-green-700"
            }`}
          >
            {details}
          </p>
        </div>
        <button onClick={() => dispatch(resetNotification())}>
          <XIcon className="h-5 w-5 text-gray-500" />
        </button>
      </div>
    </Transition>
  );
};

export default Notification;
