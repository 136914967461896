import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as CityForm from "../../services/cityForm";
import { flashSuccessNotification } from "./notificationSlice";

// selectors

export const selectCityFormOptions = state =>
  state.cityForm.cityFormOptions.map(c => ({
    value: c.id,
    label: c.name,
  }));

export const selectAllCityFormNamesAndIds = state =>
  state.cityForm.cityForms.map(c => ({ id: c.id, name: c.name }));

// actions

export const readAllCityFormsAsync = createAsyncThunk(
  "cityForms/readAll",
  async (unused_var, { dispatch }) => {
    try {
      const cityForms = await CityForm.getAll();
      await dispatch(readAllCityFormOptions());
      return { cityForms: cityForms.rows }; // .rows because it's a paginated request by default
    } catch (error) {
      console.error("Failed to read all cityForms", error);
    }
  }
);

export const readAllCityFormOptions = createAsyncThunk("cityForms/fetchOptions", async () => {
  try {
    const cityFormOptions = await CityForm.getAllOptions();
    return cityFormOptions;
  } catch (error) {
    console.error("Failed to fetch cityForm options", error);
  }
});

export const upsertCityFormAsync = createAsyncThunk(
  "cityForm/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await CityForm.upsert(payload);
      dispatch(readAllCityFormsAsync());
      return {};
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteCityFormAsync = createAsyncThunk(
  "cityForm/delete",
  async (payload, { dispatch }) => {
    await axios.delete(`/api/floor-plan/${payload.id}`);
    dispatch(flashSuccessNotification("CityForm deleted successfully."));
    dispatch(readAllCityFormsAsync());
    return { id: payload.id };
  }
);

export const cityFormSlice = createSlice({
  name: "cityForm",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    // cityForms is the paginated detailed data we
    // use for display and editing
    cityForms: [],
    // cityFormOptions is used to populate dropdowns
    // contains all cityForms but with fewer details
    // contains { id, name } , use a selector that maps
    // onto { label, values }
    cityFormOptions: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllCityFormsAsync.pending]: state => {
      state.loading = true;
    },
    [readAllCityFormsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.cityForms = action.payload.cityForms;
    },
    [readAllCityFormsAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [readAllCityFormOptions.fulfilled]: (state, action) => {
      state.cityFormOptions = action.payload;
    },
    [upsertCityFormAsync.pending]: state => {
      state.loading = true;
    },
    [upsertCityFormAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [upsertCityFormAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteCityFormAsync.pending]: state => {
      state.loading = true;
    },
    [deleteCityFormAsync.fulfilled]: (state, action) => {
      state.loading = false;
      // state.cityForms = state.cityForms.filter(cityForm => cityForm.id !== action.payload.id);
    },
    [deleteCityFormAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = cityFormSlice.actions;
export default cityFormSlice.reducer;
