// related to, but not the same as, ekotrope reports
export const reportTypeOptions = {
  modeling: "Modeling",
  thermalEnvelope: "Thermal Envelope",
  manualJ: "ManualJ",
  energyStar: "EnergyStar",
  airInfiltration: "Air Infiltration",
  breakerBoxLabel: "Breaker Box Label",
  projected: "Projected",
  confirmed: "Confirmed",
};
