import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import * as FloorPlan from "../../services/floor-plan";
import { flashSuccessNotification } from "./notificationSlice";

// selectors

export const selectFloorPlanOptions = state =>
  state.floorPlan.floorPlanOptions.map(c => ({
    value: c.id,
    label: c.name,
    CustomerId: c.CustomerId,
  }));

export const selectAllFloorPlanNamesAndIds = state =>
  state.floorPlan.floorPlans.map(c => ({ id: c.id, name: c.name }));

export const selectFloorPlanNamesWithPlantIds = state =>
  state.plant.plants.map(p => ({ PlantId: p.id, name: p.FloorPlan?.name }));

export const useFloorPlanIdFromPlantId = plantId => {
  return useSelector(state => state.plant.plants.find(p => p.id === plantId)?.FloorPlanId);
};

// actions

export const readAllFloorPlansAsync = createAsyncThunk(
  "floorPlans/readAll",
  async (unused_var, { dispatch }) => {
    try {
      const floorPlans = await FloorPlan.getAll();
      await dispatch(readAllFloorPlanOptions());
      return { floorPlans: floorPlans.rows }; // .rows because it's a paginated request by default
    } catch (error) {
      console.error("Failed to read all floorPlans", error);
    }
  }
);

export const readAllFloorPlanOptions = createAsyncThunk("floorPlans/fetchOptions", async () => {
  try {
    const floorPlanOptions = await FloorPlan.getAllOptions();
    return floorPlanOptions;
  } catch (error) {
    console.error("Failed to fetch floorPlan options", error);
  }
});

export const upsertFloorPlanAsync = createAsyncThunk(
  "floorPlan/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await FloorPlan.upsert(payload);
      dispatch(readAllFloorPlansAsync());
      return {};
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteFloorPlanAsync = createAsyncThunk(
  "floorPlan/delete",
  async (payload, { dispatch }) => {
    await axios.delete(`/api/floor-plan/${payload.id}`);
    dispatch(flashSuccessNotification("FloorPlan deleted successfully."));
    dispatch(readAllFloorPlansAsync());
    return { id: payload.id };
  }
);

export const floorPlanSlice = createSlice({
  name: "floorPlan",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    // floorPlans is the paginated detailed data we
    // use for display and editing
    floorPlans: [],
    // floorPlanOptions is used to populate dropdowns
    // contains all floorPlans but with fewer details
    // contains { id, name } , use a selector that maps
    // onto { label, values }
    floorPlanOptions: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllFloorPlansAsync.pending]: state => {
      state.loading = true;
    },
    [readAllFloorPlansAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.floorPlans = action.payload.floorPlans;
    },
    [readAllFloorPlansAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [readAllFloorPlanOptions.fulfilled]: (state, action) => {
      state.floorPlanOptions = action.payload;
    },
    [upsertFloorPlanAsync.pending]: state => {
      state.loading = true;
    },
    [upsertFloorPlanAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [upsertFloorPlanAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteFloorPlanAsync.pending]: state => {
      state.loading = true;
    },
    [deleteFloorPlanAsync.fulfilled]: (state, action) => {
      state.loading = false;
      // state.floorPlans = state.floorPlans.filter(floorPlan => floorPlan.id !== action.payload.id);
    },
    [deleteFloorPlanAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = floorPlanSlice.actions;
export default floorPlanSlice.reducer;
