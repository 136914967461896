import React from "react";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import FormikFieldWithLabelAndError from "../common/FormikFieldWithLabel";
import { Formik, Form } from "formik";

export function tryParseJSON(value) {
  try {
    return JSON.parse(value);
  } catch (err) {
    return [];
  }
}

const supportedTypes = ["text", "number", "date", "email", "tel", "url"];
function parseType(type) {
  if (supportedTypes.includes(type)) return type;
  else return "text";
}

const supportedAs = ["text", "textarea", "select", "radio", "checkbox"];
function parseAs(type) {
  if (supportedAs.includes(type)) return type;
  else return undefined;
}

function getClassNames(type) {
  if (type === "textarea") return "w-full col-span-2";
  else return "w-full";
}

function Options({ options }) {
  return (
    <>
      <option value="">---</option>
      {options.map(option => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </>
  );
}

const MultiFieldRepeatableForm = ({ values = [], onChange, schema, title = "Add Objects" }) => {
  if (!schema) throw new Error("schema is required");

  const handleChange = async event => {
    try {
      const [fieldName, index] = event.target.id.split("-");
      // clone the array and the object at the index
      // to prevent error "Cannot assign to read only property 'fieldName' of object '#<Object>'"
      const newValues = [...values];
      newValues[index] = { ...newValues[index] };
      newValues[index][fieldName] = event.target.value;
      onChange(newValues);
    } catch (err) {
      console.error(err);
    }
  };

  function handleAdd() {
    onChange([...values, {}]);
  }

  function handleDelete(fileIndex) {
    const newValues = [...values];
    newValues.splice(fileIndex, 1);
    onChange(newValues);
  }

  return (
    <Formik id="multi-field-form-container" onSubmit={e => e.preventDefault()}>
      <>
        <h2 className="text-lg text-gray-900">{title}</h2>
        {values.map((obj, index) => (
          <Form key={index} className="grid grid-cols-2 rounded border p-4 mt-3 gap-4">
            {schema.map(({ name, label, type, options }) => (
              <FormikFieldWithLabelAndError
                key={name}
                name={`${name}-${index}`}
                label={label}
                type={parseType(type)}
                as={parseAs(type)}
                value={obj[name]}
                onChange={handleChange}
                className={getClassNames(type)}
              >
                {options && <Options options={options} />}
              </FormikFieldWithLabelAndError>
            ))}

            <div>
              <button
                type="button"
                className="text-sm text-red-500"
                onClick={() => handleDelete(index)}
              >
                <TrashIcon className="h-5 w-5 m-2" data-testid={`delete-object-${index}`} />
              </button>
            </div>
          </Form>
        ))}

        <button
          type="button"
          onClick={handleAdd}
          className="block cursor-pointer p-6"
          data-testid="add-object"
        >
          <PlusCircleIcon className="h-10 w-10 text-gray-700" />
        </button>
      </>
    </Formik>
  );
};

export default MultiFieldRepeatableForm;
