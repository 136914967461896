import React from "react";

export default function FilteredContactsOptions({ contactsOptions = [], customerId }) {
  if (!customerId) return null;

  const filteredContactOptions = contactsOptions.filter(
    // convert to number because dom changes values to string automatically
    contact => Number(customerId) === contact.customerId
  );
  return filteredContactOptions.map(c => (
    <option value={c.value} key={c.value}>
      {c.label}
    </option>
  ));
}
