import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// DEPRECATED and unused, HomeService is used instead
export const getHomesAsync = createAsyncThunk("api/homes", async payload => {
  const response = await axios.post(`/api/homes`, payload);
  return response.data;
});

export const frontend = createSlice({
  name: "frontend",
  initialState: {
    loading: true,
    modals: {
      submittal: {
        isOpen: false,
        target: null,
      },
      customer: {
        isModified: false,
        isOpen: false,
        target: null,
      },
      user: {
        isModified: false,
        isOpen: false,
        target: null,
      },
      contact: {
        isOpen: false,
        target: null,
      },
      trip: {
        isOpen: false,
        target: null,
      },
      plant: {
        isOpen: false,
        target: null,
      },
      park: {
        isOpen: false,
        target: null,
      },
      retailer: {
        isOpen: false,
        target: null,
      },
      registration: {
        isOpen: false,
        target: null,
      },
      floorPlan: {
        isOpen: false,
        target: null,
      },
      cityForm: {
        isModified: false,
        isOpen: false,
        target: null,
      },
      ekotropeSync: {
        isOpen: false,
        target: null,
      },
      pdf: {
        isOpen: false,
        target: null,
      },
    },
    // this piece of state can be useful if you want to open, edit, and
    // submit a sub-modal, and come back to your past modal with your
    // inputs still intact. this is in state because only one modal can render at a time
    savedModal: {
      state: {},
      target: null,
      modal: "",
    },
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setHomes: (state, action) => {
      if (action.payload) {
        state.homes = action.payload;
      }
    },
    saveModal: (state, action) => {
      state.savedModal = {
        state: action.payload.state,
        target: action.payload.target,
        modal: action.payload.modal,
      };
    },
    /**
     *
     * @param {*} state
     * @param {Object} action
     * @param {String} action.modal //Target modal in string.
     * @param {Number} action.target // Target id so the modal can keep track of an edit.
     */
    openModal(state, action) {
      if (!action.payload.modal) return;
      state.modals[action.payload.modal].isOpen = true;
      state.modals[action.payload.modal].target = action.payload.target;
    },
    // add action when a form modal is modified, to allow for a warning when closing
    modifyModal: (state, action) => {
      const { modal, isModified } = action.payload;
      const updatedValue = isModified !== undefined ? isModified : true; // allow setting to false
      state.modals[modal].isModified = updatedValue;
    },
    closeModal: (state, action) => {
      const modalToClose = action.payload.modal;
      state.modals[modalToClose].isOpen = false;
      state.modals[modalToClose].isModified = false;
    },
    clearModal: (state, action) => {
      const modalToClear = action.payload.modal;
      state.modals[modalToClear].target = null;
    },
  },
  extraReducers: {
    [getHomesAsync.pending]: state => {
      state.loading = true;
    },
    [getHomesAsync.fulfilled]: (state, action) => {
      state.loading = false;

      if (!action.payload.id) {
        state.error = "Wrong username or password";
      } else {
        state.homes = action.payload;
      }
    },
    [getHomesAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clearErrors, setHomes, saveModal, openModal, modifyModal, closeModal, clearModal } =
  frontend.actions;

export default frontend.reducer;
