import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikFieldWithLabel, { MyTooltip } from "../common/FormikFieldWithLabel";
import FormHeader from "../common/forms/FormHeader";
import { WithSpacesBetween } from "../common/list-wrappers";
import { SubmitButton } from "../common/Buttons";
import { closeModal } from "../../state/slices/frontend";
import * as EkotropeSyncService from "../../services/ekotrope-sync";
import { convertEmptyStringsToNull } from "../../utils/formUtils";
import {
  flashErrorNotification,
  flashSuccessNotification,
} from "../../state/slices/notificationSlice";

export default function EkotropeSyncForm() {
  return (
    <>
      <FormHeader className="mt-4">Ekotrope Sync</FormHeader>
      <EkotropeSyncPullForm />
      {/* <EkotropeSyncPushForm /> */}
    </>
  );
}

export function EkotropeSyncPullForm() {
  const dispatch = useDispatch();
  const home = useSelector(state => state.frontend.modals.ekotropeSync.target || {});

  const initialValues = {
    id: home.id || "",
    ekotropeProjectId: home.ekotropeProjectId || "",
  };

  // Validation rules
  const EkotropeSchema = Yup.object().shape({
    ekotropeProjectId: Yup.string().required("Required"),
  });

  const handleSubmit = async home => {
    const payload = convertEmptyStringsToNull(home);
    const shouldValidateEkotropeProjectId =
      initialValues.ekotropeProjectId !== payload.ekotropeProjectId;
    if (shouldValidateEkotropeProjectId) {
      const errorMessage = await EkotropeSyncService.validate(payload.ekotropeProjectId);
      if (errorMessage) return dispatch(flashErrorNotification(errorMessage));
    }

    const { error } = await EkotropeSyncService.syncPull(payload);
    if (error) return dispatch(flashErrorNotification(error));

    dispatch(flashSuccessNotification("Sync successful!"));
    dispatch(closeModal({ modal: "ekotropeSync" }));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={EkotropeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched }) => (
        <Form>
          <h4 className="mt-4 font-medium text-gray-900">Sync Pull</h4>
          <WithSpacesBetween>
            <div className="grid grid-cols-2 gap-2">
              <FormikFieldWithLabel
                className="col-span-1"
                name="ekotropeProjectId"
                label="Ekotrope Project ID"
                disabled={!!initialValues.ekotropeProjectId}
                error={touched.ekotropeProjectId && errors.ekotropeProjectId}
              />
              <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
                <SubmitButton>Sync Pull</SubmitButton>
              </div>
            </div>
          </WithSpacesBetween>
        </Form>
      )}
    </Formik>
  );
}

function FloorPlanDataWarningWithTooltip({ floorPlanName }) {
  return (
    <p className="col-span-2">
      Warning: home dimension values are coming from floor plan <i>{floorPlanName}</i>.
      <MyTooltip
        text={
          <>
            <p>You can still sync push these values, but you may not not edit them here.</p>
            <p>To edit the values here please edit the foor plan or unlink it from this home.</p>
            <p>
              Please note that if you <i>sync pull</i> home dimension values that don't match the
              floor plan, this could cause some data canflict.
            </p>
          </>
        }
      />
    </p>
  );
}

export function EkotropeSyncPushForm() {
  const dispatch = useDispatch();
  const home = useSelector(state => state.frontend.modals.ekotropeSync.target || {});

  const shouldFieldsBeDisabled = !!home.FloorPlan;

  const initialValues = {
    id: home.id || "",
    ekotropeProjectId: home.ekotropeProjectId || "",
  };
  if (home.FloorPlan) {
    initialValues.conditionedArea = home.FloorPlan.totalSqft || "";
    initialValues.conditionedVolume = home.FloorPlan.totalVolume || "";
  } else {
    initialValues.conditionedArea = home.conditionedArea || "";
    initialValues.conditionedVolume = home.conditionedVolume || "";
  }

  // Validation rules
  const EkotropeSchema = Yup.object().shape({
    ekotropeProjectId: Yup.string().required("Required"),
  });

  const handleSubmit = async home => {
    const payload = convertEmptyStringsToNull(home);
    // if (errorMessage) return dispatch(flashErrorNotification(errorMessage));

    const { error } = await EkotropeSyncService.syncPush(payload);
    if (error) return dispatch(flashErrorNotification(error));

    dispatch(flashSuccessNotification("Sync successful!"));
    dispatch(closeModal({ modal: "ekotropeSync" }));
  };

  if (!home.ekotropeProjectId) return null;

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={EkotropeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched }) => (
        <Form>
          <h4 className="mt-4 font-medium text-gray-900">Sync Push</h4>
          <WithSpacesBetween>
            <div className="grid grid-cols-2 gap-2">
              {shouldFieldsBeDisabled && (
                <FloorPlanDataWarningWithTooltip floorPlanName={home.FloorPlan.name} />
              )}
              <FormikFieldWithLabel
                className="col-span-1"
                name="conditionedArea"
                label="Conditioned Area"
                type="number"
                disabled={shouldFieldsBeDisabled}
                error={touched.conditionedArea && errors.conditionedArea}
              />
              <FormikFieldWithLabel
                className="col-span-1"
                name="conditionedVolume"
                label="Conditioned Volume"
                type="number"
                disabled={shouldFieldsBeDisabled}
                error={touched.conditionedVolume && errors.conditionedVolume}
              />
            </div>
          </WithSpacesBetween>

          <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
            <SubmitButton>Sync Push</SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}
