import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import FormHeader from "../common/forms/FormHeader";
import { WithSpacesBetween } from "../common/list-wrappers";
import { SubmitButton } from "../common/Buttons";
import { selectCustomerOptions } from "../../state/slices/customerSlice";
import { closeModal } from "../../state/slices/frontend";
import * as FloorPlanService from "../../services/floor-plan";
import MultipleFileUploader from "./MultipleFileUploader";
import { upsertFloorPlanAsync } from "../../state/slices/floorPlanSlice";
import { convertEmptyStringsToNull } from "../../utils/formUtils";

export default function StatefulFloorPlanForm() {
  const dispatch = useDispatch();
  const floorPlan = useSelector(state => state.frontend.modals.floorPlan.target || {});
  const customerOptions = useSelector(selectCustomerOptions);

  const initialValues = {
    id: floorPlan.id || "",
    CustomerId: `${floorPlan?.CustomerId || ""}`,
    name: floorPlan.name || "",
    firstFloorSqft: floorPlan.firstFloorSqft || "",
    firstFloorAvgHeight: floorPlan.firstFloorAvgHeight || "",
    firstFloorVolume: floorPlan.firstFloorVolume || "",
    secondFloorSqft: floorPlan.secondFloorSqft || "",
    secondFloorAvgHeight: floorPlan.secondFloorAvgHeight || "",
    secondFloorVolume: floorPlan.secondFloorVolume || "",
    attachments: floorPlan.attachments || [],
  };

  const handleSubmit = async floorPlan => {
    const payload = convertEmptyStringsToNull(floorPlan);
    await dispatch(upsertFloorPlanAsync(payload));
    dispatch(closeModal({ modal: "floorPlan" }));
  };

  return (
    <FloorPlanForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      customerOptions={customerOptions}
    />
  );
}

export function FloorPlanForm({ handleSubmit, customerOptions, initialValues }) {
  // Validation rules
  const FloorPlanSchema = Yup.object().shape({
    // CustomerId: Yup.string().required("Required"),
    name: Yup.string()
      .required("Required")
      .test("checkDuplicateName", "This floor plan name is already in use", async value => {
        if (initialValues.name === value) return true;
        else return FloorPlanService.validateNameUnique(value);
      }),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={FloorPlanSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values, handleChange }) => (
        <>
          {initialValues.id ? (
            <FormHeader className="mt-4">Edit Floor Plan</FormHeader>
          ) : (
            <div className="flex gap-4">New Floor Plan</div>
          )}
          <Form>
            <WithSpacesBetween>
              <div className="grid grid-cols-2 gap-2">
                <FormikFieldWithLabel
                  as="select"
                  name="CustomerId"
                  label="Builder"
                  error={touched.CustomerId && errors.CustomerId}
                >
                  <option value="">---</option>
                  {customerOptions.map(customer => (
                    <option value={customer.value} key={customer.value}>
                      {customer.label}
                    </option>
                  ))}
                </FormikFieldWithLabel>

                <FormikFieldWithLabel
                  className="col-span-1"
                  name="name"
                  label="Floor Plan Name"
                  error={touched.name && errors.name}
                />
                <FormikFieldWithLabel
                  name="firstFloorSqft"
                  label="1st floor sqft"
                  type="number"
                  className="col-span-1"
                  error={touched.firstFloorSqft && errors.firstFloorSqft}
                />
                <FormikFieldWithLabel
                  name="firstFloorAvgHeight"
                  label="1st floor avg ceiling height"
                  type="number"
                  className="col-span-1"
                  error={touched.firstFloorAvgHeight && errors.firstFloorAvgHeight}
                />
                <FormikFieldWithLabel
                  name="firstFloorVolume"
                  label="1st floor volume"
                  type="number"
                  className="col-span-1"
                  error={touched.firstFloorVolume && errors.firstFloorVolume}
                />
                <FormikFieldWithLabel
                  name="secondFloorSqft"
                  label="2nd floor sqft"
                  type="number"
                  className="col-span-1"
                  error={touched.secondFloorSqft && errors.secondFloorSqft}
                />
                <FormikFieldWithLabel
                  name="secondFloorAvgHeight"
                  label="2nd floor avg ceiling height"
                  type="number"
                  className="col-span-1"
                  error={touched.secondFloorAvgHeight && errors.secondFloorAvgHeight}
                />
                <FormikFieldWithLabel
                  name="secondFloorVolume"
                  label="2nd floor volume"
                  type="number"
                  className="col-span-1"
                  error={touched.secondFloorVolume && errors.secondFloorVolume}
                />
                <MultipleFileUploader
                  files={values.attachments}
                  onUpload={value => handleChange({ target: { name: "attachments", value } })}
                />
              </div>
            </WithSpacesBetween>

            <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
              <SubmitButton />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
