import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, upsertUserAsync } from "../../state/slices/userSlice";
import { loginBuilderAsync } from "../../state/slices/authSlice";
import { revalidateCustomerIdAsync, upsertCustomerAsync } from "../../state/slices/customerSlice";
import Logo from "../nav/Logo";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function BuilderSignUp() {
  const [state, setState] = useState({}); //form
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isCreatingNewBuilder] = useState(false);
  const { generalError } = useSelector(state => state.user);
  const { generalError: customerGeneralError } = useSelector(state => state.customer);
  const { customerId } = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const queryCustomerId = +query.get("customerId");

  useEffect(() => {
    dispatch(revalidateCustomerIdAsync({ id: queryCustomerId }));
  }, [dispatch, queryCustomerId]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (state.password !== state.confirm) {
      setError("Password and confirm password must match.");
      return;
    }
    delete state.confirm;
    setLoading(true);
    try {
      if (isCreatingNewBuilder) {
        const customerPostRes = await dispatch(
          upsertCustomerAsync({
            name: state.customerName,
            contactName: state.name,
            contactPhone: state.phoneNumber,
            contactEmail: state.email,
          }),
        );
        state.CustomerId = customerPostRes.payload.customer[0].id;
      }
      const userAction = await dispatch(
        upsertUserAsync({
          name: state.name,
          CustomerId: state.CustomerId,
          phoneNumber: state.phoneNumber,
          email: state.email,
          password: state.password,
          role: "builder",
        }),
      );
      if (userAction.type === "user/upsert/fulfilled") {
        const body = { email: state.email, password: state.password };
        const loginAction = await dispatch(loginBuilderAsync(body));
        if (loginAction.type === "auth/builder/signin/fulfilled") {
          return history.push("/");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClearErrors = () => {
    dispatch(clearErrors());
    setError("");
  };

  useEffect(() => {
    // load CustomerId into the form state from a param value, rather than from a form field.
    if (customerId) {
      handleChange({ target: { name: "CustomerId", value: customerId } });
    }
  }, [customerId]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center">
          <Logo className="w-12 h-12" />
          <p className="font-semibold ml-3 text-lg">Builder</p>
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your builder account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Or{" "}
          <Link to="/auth/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
            already have an account?
          </Link>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <ul className="text-red-700 my-2">
              {generalError && <li test-id="generalError">{generalError}</li>}
              {customerGeneralError && (
                <li test-id="customerGeneralError">{customerGeneralError}</li>
              )}
            </ul>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Full name
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="name"
                  value={(state && state.name) || ""}
                  onChange={handleChange}
                  autoFocus
                  name="name"
                  autoComplete="name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                Phone number
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="phoneNumber"
                  value={(state && state.phoneNumber) || ""}
                  onChange={handleChange}
                  name="phoneNumber"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="email"
                  value={(state && state.email) || ""}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="new-password"
                  onChange={e =>
                    handleChange({ target: { name: "password", value: e.target.value } })
                  }
                  name="new-password"
                  type="password"
                  value={(state && state.password) || ""}
                  onFocus={handleClearErrors}
                  autoComplete="off"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="confirm password"
                  onChange={e =>
                    handleChange({ target: { name: "confirm", value: e.target.value } })
                  }
                  type="password"
                  value={(state && state.confirm) || ""}
                  autoComplete="new-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ul className="text-red-700 my-2">
                <li>{error && error}</li>
              </ul>
            </div>

            <div>
              <button
                type="submit"
                disabled={
                  isLoading || error || generalError || customerGeneralError || !state.CustomerId
                }
                style={{
                  opacity:
                    isLoading || error || generalError || customerGeneralError || !state.CustomerId
                      ? "50%"
                      : "initial",
                }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create your account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
