import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PencilIcon,
  // TrashIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { TableWithPagination } from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";
import InspectionService from "../../services/inspection";
import { selectAllCustomerNamesAndIds } from "../../state/slices/customerSlice";
import {
  createQueryString,
  getPageFromSearchParams,
  getStartingFilters,
} from "../../utils/searchParams";
import Modal from "../common/Modal";
import { InspectionFiltersForm } from "../forms/InspectionFiltersForm";
// import { useUserOptions } from "../../state/slices/userSlice";
import HomeFormModal from "../forms/HomeFormModal";

export default function Inspections() {
  const [homes, setHomes] = useState([]);
  const history = useHistory();
  const startingPage = getPageFromSearchParams(history?.location?.search) || 1;
  const [page, setPage] = useState(startingPage);

  const [totalPages, setTotalPages] = useState(1);
  const startingFilters = getStartingFilters(history?.location?.search) || {};
  const [filters, setFilters] = useState(startingFilters);
  const filtersCount = useMemo(() => Object.values(filters).filter(Boolean).length, [filters]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isHomeFormModalOpen, setIsHomeFormModalOpen] = useState(false);
  const [editingHomeId, setEditingHomeId] = useState(null);
  const editingHome = homes.find(h => h.id === editingHomeId) || {};

  const loadData = useCallback(async () => {
    const { homes, pages } = await InspectionService.getHomesWithScheduledInspections({
      page,
      ...filters,
    });
    setHomes(homes);
    setTotalPages(pages);
  }, [page, filters]);

  const handlePageChange = newPage => {
    const query = createQueryString({ page: newPage, ...filters });
    history.push(`/inspections${query}`);
    setPage(() => newPage);
  };

  const handleSearch = async newFilters => {
    setFilters(newFilters);
    setPage(1); // any change in search should always reset page to 1
    const query = createQueryString({ page: 1, ...newFilters });
    history.push(`/inspections${query}`);
    setIsFiltersModalOpen(false);
  };

  const customers = useSelector(selectAllCustomerNamesAndIds);
  // const users = useUserOptions();

  // initial load and reload when modal is closed (add or edit inspection)
  useEffect(() => {
    if (!isHomeFormModalOpen) loadData();
  }, [isHomeFormModalOpen, page, filters, loadData]);

  const getCustomerNameById = useMemo(() => {
    return id => customers.find(p => p.id === id)?.name;
  }, [customers]);
  // const getUserNameById = useMemo(() => {
  //   return id => users.find(p => p.value === id)?.label;
  // }, [users]);

  const handleEditInspection = inspection => {
    setEditingHomeId(inspection.id);
    setIsHomeFormModalOpen(true);
  };

  // const handleDelete = async (e, inspection) => {
  //   e.stopPropagation();
  //   const confirm = window.confirm("Deleting an inspection is irreversible.\nAre you sure?");
  //   if (!confirm) return;

  //   await InspectionService.delete(inspection.id);
  //   loadInspections();
  // };

  const inspectionColumns = [
    {
      caption: "Address",
      cellDisplay: value => value.streetAddress,
      class: "text-left",
    },
    {
      caption: "City",
      cellDisplay: value => value.city,
    },
    // {
    //   caption: "Scheduled For",
    //   dataField: "scheduledFor",
    //   dataType: "date",
    // },
    // {
    //   caption: "Inspector",
    //   cellDisplay: value => getUserNameById(value.UserId),
    // },
    {
      caption: "Builder",
      cellDisplay: value => getCustomerNameById(value?.CustomerId),
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`inspection-edit-${value.id}`}
              onClick={e => {
                e.stopPropagation();
                handleEditInspection(value);
              }}
              Icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />

            {/* <TableButton
              testId={`inspection-delete-${value.id}`}
              onClick={e => handleDelete(e, value)}
              Icon={TrashIcon}
              className="text-red-700"
            /> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Inspections</h1>
          <div className="flex gap-2">
            <Button onClick={() => setIsFiltersModalOpen(true)}>
              <SearchIcon className="mr-2 h-4 text-white inline-block" />
              Filters {filtersCount ? `(${filtersCount})` : ""}
            </Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={inspectionColumns}
            className=""
            onRowClick={handleEditInspection}
            items={homes || []}
            maxPage={totalPages}
            currentPage={page}
            setPage={handlePageChange}
          />
        </div>
        <Modal modalShowing={isFiltersModalOpen} closeModal={() => setIsFiltersModalOpen(false)}>
          <InspectionFiltersForm searchHandler={handleSearch} filters={filters} />
        </Modal>
        <HomeFormModal
          isOpen={isHomeFormModalOpen}
          home={editingHome}
          refreshHomes={loadData}
          onClose={() => setIsHomeFormModalOpen(false)}
        />
      </main>
    </div>
  );
}
