import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HomeService from "../../services/home";
import { flashSuccessNotification } from "./notificationSlice";

// Selectors

export const selectHomeById = (submittalId, homeId) => state => {
  const submittal = state.submittal.submittals.find(
    submittal => submittal.id === Number(submittalId),
  );
  const home = submittal?.Homes?.find(home => home.id === Number(homeId));
  return home || null;
};

// Actions

export const upsertHomeAsync = createAsyncThunk("home/upsert", async (payload, { dispatch }) => {
  const response = await HomeService.upsert(payload);
  dispatch(flashSuccessNotification("Successfully saved home."));
  return response;
});

// This slice is not currently used but the thunk upsertHomeAsync is.

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    loading: false,
    homes: [],
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setHomes: (state, action) => {
      if (action.payload) {
        state.homes = action.payload;
      }
    },
  },
  extraReducers: {
    [upsertHomeAsync.pending]: state => {
      state.loading = true;
    },
    [upsertHomeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [upsertHomeAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clearErrors, setHomes } = homeSlice.actions;
export default homeSlice.reducer;
