import axios from "axios";

/**
 * Validates the Ekotrope project with the given ID.
 * @param {string} ekotropeProjectId - The ID of the Ekotrope project to validate.
 * @returns {Promise<string|null>} - Returns an error message (string) if validation fails, otherwise returns null.
 */
export const validate = async ekotropeProjectId => {
  try {
    await axios.get("/api/ekotrope/validate", { params: { ekotropeProjectId } });
    return null;
  } catch (error) {
    return error.response.data.error;
  }
};

export const syncPull = async payload => {
  try {
    const { data } = await axios.put(`/api/ekotrope/sync-pull`, payload);
    return data;
  } catch (error) {
    console.error("Error in syncPull: ", error);
    return { error: error?.response?.data?.error || "Something went wrong" };
  }
};

export const syncPush = async payload => {
  try {
    const { data } = await axios.put(`/api/ekotrope/sync-push`, payload);
    return data;
  } catch (error) {
    console.error("Error in syncPush: ", error);
    return { error: error?.response?.data?.error || "Something went wrong" };
  }
};
