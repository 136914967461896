import axios from "axios";
import { createQueryString } from "../utils/searchParams";
import { nanoid } from "nanoid";

export const uploadImageToS3 = async (file, fvId, field) => {
  const extension = file.type.split("/")[1];
  // get the url with correct headers to upload to S3; the back-end has the appropriate access keys
  const signedUrlRes = await axios.get(
    "/api/get-signed-url" + createQueryString({ fvId: fvId, field, extension })
  );
  // upload photo to S3
  await axios.put(signedUrlRes.data.signedRequest, file);

  return "/" + signedUrlRes.data.url; // resource location in S3
};

export const uploadAttachmentToStorj = async file => {
  const mimeType = file.type;
  const uploadPath = composeAttachmentUploadPath(file.name);
  // get the url with correct headers to upload to Storj; the back-end has the appropriate access keys
  const signedUrlRes = await axios.get(
    "/api/get-signed-url" + createQueryString({ mimeType, uploadPath })
  );
  // upload photo to Storj
  await axios.put(signedUrlRes.data.signedRequest, file);

  return signedUrlRes.data.url; // resource location in Storj, returned with a leading slash
};

export function composeAttachmentUploadPath(fileName) {
  const splitFileName = fileName.split(".");
  const extension = splitFileName[splitFileName.length - 1];
  const fileNameWithoutExtension = splitFileName.slice(0, -1).join(".");
  const randomString = getRandomStringWithoutDash();
  return `/attachments/${fileNameWithoutExtension}-${randomString}.${extension}`;
}

function getRandomStringWithoutDash() {
  // nanoid can return letters (upper and lower case), numbers, underscore, and dash
  return nanoid(15).replace(/-/g, "");
}

export function getFileNameFromAttachmentPath(path) {
  // assumes path is like "/attachments/fileName-nanoid.extension"
  // discard "/attachments/" prefix and nanoid, returning "fileName.extension"
  const splitPath = path.split("/");
  const fileName = splitPath[2];
  const splitFileName = fileName.split(".");
  const extension = splitFileName[splitFileName.length - 1];
  const fileNameWithoutExtension = splitFileName.slice(0, -1).join(".");
  const fileNameWithoutNanoid = fileNameWithoutExtension.split("-").slice(0, -1).join("-");
  return `${fileNameWithoutNanoid}.${extension}`;
}
