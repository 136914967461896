import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, loginAsync, loginBuilderAsync } from "../../state/slices/authSlice";
import Logo from "../nav/Logo";
import { getIsOnBuilderWebsite } from "../../utils/isBuilderWebsite";

export default function SignIn() {
  const { error, loading } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const isBuilderWebsite = getIsOnBuilderWebsite();

  const createAcctLink = "/auth/signup";

  const handleSubmit = async e => {
    e.preventDefault();
    const {
      email: { value: email },
      password: { value: password },
    } = e.target.elements;
    try {
      const action = isBuilderWebsite ? loginBuilderAsync : loginAsync;
      const loginAction = await dispatch(action({ email, password }));
      const successActionName = isBuilderWebsite
        ? "auth/builder/signin/fulfilled"
        : "auth/signin/fulfilled";
      if (loginAction.type === successActionName) {
        return history.push("/");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center">
          <Logo className="w-12 h-12" />
          {isBuilderWebsite && <p className="font-semibold ml-3 text-lg">Builder</p>}
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Don't have an account?{" "}
          {isBuilderWebsite ? (
            <span className="text-center text-sm text-gray-600 max-w">
              <a
                href="mailto:test@example.com"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Contact us to get a sign-up link
              </a>
              !
            </span>
          ) : (
            <Link to={createAcctLink} className="font-medium text-indigo-600 hover:text-indigo-500">
              Create account
            </Link>
          )}
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  autoFocus
                  type="email"
                  onFocus={() => dispatch(clearErrors())}
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ul className="text-red-700 my-2">
                <li>{error && error}</li>
              </ul>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  onFocus={() => dispatch(clearErrors())}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/auth/forgot"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                style={{ opacity: loading || error ? "50%" : "initial" }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
