import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { openModal } from "../../state/slices/frontend";
import { TableWithPagination } from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";
import * as CityFormService from "../../services/cityForm";

export default function CityForms() {
  const [cityForms, setCityForms] = useState([]);
  const dispatch = useDispatch();
  const cityFormModalIsOpen = useSelector(state => state.frontend.modals.cityForm.isOpen);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadCityForms = useCallback(async () => {
    const { rows, pages } = await CityFormService.getAll({ page });
    setCityForms(rows);
    setTotalPages(pages);
  }, [page]);

  // initial load and reload when modal is closed (add or edit city form)
  useEffect(() => {
    if (!cityFormModalIsOpen) loadCityForms();
  }, [cityFormModalIsOpen, loadCityForms]);

  const handleEditCityForm = cityForm => {
    dispatch(openModal({ modal: "cityForm", target: cityForm }));
  };
  const handleAddCityForm = () => {
    dispatch(openModal({ modal: "cityForm" }));
  };

  const handleDelete = async (e, cityForm) => {
    e.stopPropagation();
    const confirm = window.confirm("Deleting a city form is irreversible.\nAre you sure?");
    if (!confirm) return;

    await CityFormService.remove(cityForm.id);
    loadCityForms();
  };

  const CityFormColumns = [
    {
      dataField: "city",
      caption: "City",
      class: "text-left",
    },
    {
      caption: "State",
      dataField: "state",
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`city-form-edit-${value.id}`}
              onClick={() => handleEditCityForm(value)}
              Icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />

            <TableButton
              testId={`city-form-delete-${value.id}`}
              onClick={e => handleDelete(e, value)}
              Icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">City Forms</h1>
          <div className="flex gap-2">
            <Button onClick={handleAddCityForm}>Add</Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={CityFormColumns}
            className=""
            onRowClick={handleEditCityForm}
            items={cityForms || []}
            maxPage={totalPages}
            currentPage={page}
            setPage={setPage}
          />
        </div>
      </main>
    </div>
  );
}
