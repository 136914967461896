import { useSelector } from "react-redux";
import Homes from "./Homes";
import BuilderHomes from "./BuilderHomes";

export default function HomePage() {
  const currentUser = useSelector(state => state.auth.currentUser);
  const isBuilder = currentUser.role === "builder";

  if (isBuilder) return <BuilderHomes />;
  else return <Homes />;
}
