import React, { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import FormHeader from "../common/forms/FormHeader";
import { selectCustomerOptions } from "../../state/slices/customerSlice";
import { SmallGreenButton, SmallRedButton } from "../common/Buttons";
import { reactSelectStyles } from "../forms/HomeFormModal";
import { getIsOnBuilderWebsite } from "../../utils/isBuilderWebsite";

export function SearchHomesForm({ searchHandler, initialValues = {} }) {
  const customerOptions = useSelector(selectCustomerOptions);
  const isOnBuilderWebsite = getIsOnBuilderWebsite();

  const [state, setState] = useState({
    streetAddress: initialValues.streetAddress || "",
    testingType: initialValues.testingType || "",
    CustomerId: initialValues.CustomerId || "",
    readyToSchedule: initialValues.readyToSchedule || "",
  });

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    setState(prevState => ({ ...prevState, [name]: newValue }));
  };

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    searchHandler(state);
  };

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    searchHandler({});
  };

  return (
    <>
      <form onSubmit={onSubmitHandler} className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg">
        <FormHeader className="col-span-2">Search Homes</FormHeader>
        <div className="col-span-1">
          <label htmlFor="streetAddress" className="text-sm text-gray-900">
            Street Address
          </label>
          <input
            autoFocus
            id="streetAddress"
            name="streetAddress"
            onChange={handleChange}
            value={state.streetAddress}
            className="resize-none bg-white block w-full px-3 py-2 mt-2 h-10 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        {!isOnBuilderWebsite && (
          <div>
            <label
              htmlFor="CustomerId"
              id="CustomerId-label"
              className="block text-sm text-gray-900 mb-2"
            >
              Builder
            </label>
            <Select
              options={customerOptions}
              isSearchable={true}
              isClearable={true}
              onChange={e => {
                handleChange({
                  target: { name: "CustomerId", value: e?.value?.toString() || "" },
                });
              }}
              // eslint-disable-next-line eqeqeq
              value={customerOptions.find(c => c.value == state.CustomerId) || ""}
              id="CustomerId"
              name="CustomerId"
              placeholder="Select Builder"
              styles={reactSelectStyles}
            />
          </div>
        )}
        {!isOnBuilderWebsite && (
          <div className="col-span-1">
            <label htmlFor="testingType" className="text-sm text-gray-900">
              Testing Type
            </label>
            <select
              name="testingType"
              id="testingType"
              onChange={handleChange}
              value={state.testingType}
              className="resize-none bg-white block w-full px-3 py-2 mt-2 h-10 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">---</option>
              <option value="testingOnly">Testing Only</option>
              <option value="hersOnly">HERS Only</option>
              <option value="fullEnergyCompliance">Full Energy Compliance</option>
              <option value="fullEnergyComplianceAndEnergyStar">
                Full Energy Compliance and Energy Star
              </option>
            </select>
          </div>
        )}
        <div className="col-span-1 flex align-items-middle text-gray-900 py-2 pr-4">
          <input
            name="readyToSchedule"
            id="readyToSchedule"
            onChange={handleChange}
            checked={state.readyToSchedule}
            type="checkbox"
            className="resize-none bg-white mr-3 border rounded-md  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
          <label htmlFor="readyToSchedule" className="text-sm my-auto">
            Ready to schedule
          </label>
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} type="button" className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}
