import axios from "axios";

class InspectionService {
  async getMany({ page, ...filters }) {
    const route = "/api/inspections";
    const { data } = await axios.get(route, { params: { page, ...filters } });
    return { inspections: data.rows, pages: data.pages };
  }
  async getHomesWithScheduledInspections({ page, ...filters }) {
    const route = "/api/homes";
    const { data } = await axios.get(route, { params: { page, ...filters, scheduledOnly: true } });
    return { homes: data.rows, pages: data.pages };
  }
  async create(inspection) {
    const { data } = await axios.post("/api/inspection", inspection);
    return data;
  }
  async update(inspection) {
    const { data } = await axios.put("/api/inspection", inspection);
    return data;
  }
  async delete(id) {
    const route = `/api/inspection/${id}`;
    const { data } = await axios.delete(route);
    return data;
  }
}

export const inspectionOptions = {
  polyseal: "Polyseal",
  ductTest: "Duct Test",
  roughInspection: "Rough Inspection",
  insulation: "Insulation",
  blowerDoor: "Blower Door",
  ductTestES: "Duct Test (ES)",
  energyInspectionFinal: "Energy Inspection Final",
};

export const inspectionPassFailOptions = {
  pass: "Pass",
  fail: "Fail",
  nri: "NRI",
  passWithCorrectionsProceed: "Pass with Corrections Proceed",
  correctAndReschedule: "Correct and Reschedule",
  passWithCorrectionCheckAtInsulation: "Pass with Correction Check at Insulation",
  cancelledByBuilder: "Cancelled by Builder",
};

export default new InspectionService();
