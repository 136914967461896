import axios from "axios";

class HomeService {
  async getAll({ page, ...filters }) {
    const route = "/api/homes";
    const { data } = await axios.get(route, { params: { page, ...filters } });
    return { homes: data.rows, pages: data.pages };
  }
  async get(id) {
    const route = `/api/home/${id}`;
    const { data } = await axios.get(route);
    return data;
  }
  async upsert(home) {
    const route = "/api/home";
    const { data } = await axios.post(route, home);
    return data;
  }
  async bulkUpsert(homes) {
    const responses = homes.map(home => {
      return this.upsert(home);
    });
    return Promise.all(responses);
  }
  async delete(id) {
    const route = `/api/home/${id}`;
    const { data } = await axios.delete(route);
    return data;
  }
}

export default new HomeService();
