import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { closeModal, modifyModal } from "../../state/slices/frontend";
import { upsertCustomerAsync } from "../../state/slices/customerSlice";
import Input from "../common/FormikFieldWithLabel";
import Legend from "../common/Legend";
import Header from "../common/Header";
import { SubmitButton } from "../common/Buttons";
import { validateBuilderNameUnique } from "../../services/customer";
import { USAStates } from "../../utils/constants";
import MultiFieldRepeatableForm from "./MultiFieldRepeatableForm";

export default function CustomerForm() {
  const { modals } = useSelector(state => state.frontend);
  const dispatch = useDispatch();
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (isModified) dispatch(modifyModal({ modal: "customer" }));
  }, [isModified, dispatch]);

  const handleSubmitCustomer = async payload => {
    await dispatch(upsertCustomerAsync(payload));
    dispatch(closeModal({ modal: "customer" }));
  };

  const initialValues = modals.customer.target || {
    name: "",
    officeStreetAddress: "",
    officeCity: "",
    officeState: "",
    officeZip: "",
    notes: "",
    planNameDropdownRequired: false,
    purchaseOrdersRequired: false,
    reInspectionCharge: false,
    manualJCharge: false,
    mainContactName: "",
    mainContactTitle: "",
    mainContactEmail: "",
    mainContactOfficePhone: "",
    mainContactCellPhone: "",
    builderRequestors: [],
  };

  const CustomerSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .test("checkDuplicateName", "A builder with this name already exists", async value => {
        if (initialValues.name === value) return true;
        else return validateBuilderNameUnique(value);
      }),
  });

  const pointOfContactSchema = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "notes", label: "Notes" },
  ];

  return (
    <Formik
      className="p-4 "
      initialValues={initialValues}
      validationSchema={CustomerSchema}
      enableReinitialize
      onSubmit={handleSubmitCustomer}
    >
      {({ errors, touched, values, handleChange }) => {
        function handleInputChange(e) {
          setIsModified(true);
          handleChange(e);
        }

        return (
          <Form>
            <Header>{initialValues?.id ? "Edit Builder" : "Add Builder"}</Header>
            <div className="grid grid-cols-2">
              <Input
                autoFocus
                name="name"
                className="col-span-2"
                label="Name"
                onChange={handleInputChange}
                error={errors.name && touched.name ? errors.name : null}
              />
              <Input
                name="officeStreetAddress"
                label="Office Address"
                className="col-span-1"
                onChange={handleInputChange}
              />
              <Input
                name="officeCity"
                label="Office City"
                className="col-span-1"
                onChange={handleInputChange}
              />
              <Input
                name="officeZip"
                label="Office Zip"
                className="col-span-1"
                onChange={handleInputChange}
              />
              <Input
                as="select"
                name="officeState"
                label="Office State"
                onChange={handleInputChange}
                error={touched.state && errors.state}
              >
                <option value="">---</option>
                {USAStates.map(state => (
                  <option value={state} key={state}>
                    {state}
                  </option>
                ))}
              </Input>
              <Input
                className="col-span-2"
                name="notes"
                label="Notes"
                as="textarea"
                fieldClass="h-24 resize-y"
                onChange={handleInputChange}
              />
              <Input
                name="planNameDropdownRequired"
                label="Plan Name Dropdown Required"
                type="checkbox"
                className="col-span-2 mt-2"
                onChange={handleInputChange}
              />
              <Input
                name="purchaseOrdersRequired"
                label="Purchase Orders Required"
                type="checkbox"
                className="col-span-2"
                onChange={handleInputChange}
              />
              <Input
                name="reInspectionCharge"
                label="Re-Inspection Charge"
                type="checkbox"
                className="col-span-2"
                onChange={handleInputChange}
              />
              <Input
                name="manualJCharge"
                label="Manual J Charge"
                type="checkbox"
                className="col-span-2 mb-2"
                onChange={handleInputChange}
              />
              <Legend className="col-span-2">Main Contact</Legend>
              <Input
                name="mainContactName"
                label="Contact Name"
                className="col-span-1"
                onChange={handleInputChange}
              />
              <Input
                name="mainContactTitle"
                label="Contact Title"
                className="col-span-1"
                onChange={handleInputChange}
              />
              <Input
                name="mainContactEmail"
                label="Contact Email"
                className="col-span-1"
                type="email"
                onChange={handleInputChange}
              />
              <Input
                name="mainContactOfficePhone"
                label="Office Phone"
                type="tel"
                className="col-span-1"
                pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
                onChange={handleInputChange}
              />
              <Input
                name="mainContactCellPhone"
                label="Cell Phone"
                type="tel"
                className="col-span-1"
                pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
                onChange={handleInputChange}
              />
            </div>
            <p className=" text-sm my-2">Inspection types: coming soon...</p>
            <p className=" text-sm my-2">Invoice emails: coming soon...</p>
            <MultiFieldRepeatableForm
              values={values.builderRequestors}
              onChange={value =>
                handleInputChange({ target: { name: "builderRequestors", value } })
              }
              schema={pointOfContactSchema}
              title="Builder Requestors"
            />
            <MultiFieldRepeatableForm
              values={values.inspectionPOCs}
              onChange={value => handleInputChange({ target: { name: "inspectionPOCs", value } })}
              schema={pointOfContactSchema}
              title="Inspection Contacts"
            />
            <div className="flex justify-end m-4 mt-6">
              <SubmitButton />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
