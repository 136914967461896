import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EyeIcon, PlusCircleIcon, SearchIcon } from "@heroicons/react/outline";
import { TableWithPagination } from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";
import HomeService from "../../services/home";
import { selectAllCustomerNamesAndIds } from "../../state/slices/customerSlice";
import { createQueryString, getPageFromSearchParams, getStartingFilters } from "../../utils/searchParams";
import Modal from "../common/Modal";
import { SearchHomesForm } from "./SearchHomesForm";
import HomeFormModal from "../forms/HomeFormModal";
import RequestInspectionFormModal from "../forms/RequestInspectionFormModal";

export function getRequiredInspectionsNotAlreadyPending(home) {
  const requestedInspections = home.Inspections?.map(inspection => inspection.inspectionType);
  const notRequestedInspections = home.requiredInspections?.filter(type => !requestedInspections.includes(type));
  return notRequestedInspections
}

export default function BuilderHomesPage() {
  const [homes, setHomes] = useState([]);
  const history = useHistory();
  const startingPage = getPageFromSearchParams(history?.location?.search) || 1;
  const [page, setPage] = useState(startingPage);

  const ekotropeSyncModalIsOpen = useSelector(state => state.frontend.modals.ekotropeSync.isOpen);
  const [totalPages, setTotalPages] = useState(1);
  const startingFilters = getStartingFilters(history?.location?.search) || {};
  const [filters, setFilters] = useState(startingFilters);
  const filtersCount = useMemo(() => Object.values(filters).filter(Boolean).length, [filters]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [isHomeFormModalOpen, setIsHomeFormModalOpen] = useState(false);
  const [editingHomeId, setEditingHomeId] = useState(null);
  const editingHome = homes.find(h => h.id === editingHomeId) || {};

  const loadHomes = useCallback(async () => {
    const { homes, pages } = await HomeService.getAll({ page, ...filters });
    setHomes(homes);
    setTotalPages(pages);
  }, [page, filters]);

  const handlePageChange = newPage => {
    const query = createQueryString({ page: newPage, ...filters });
    history.push(`/homes${query}`);
    setPage(() => newPage);
  };

  const handleSearch = async newFilters => {
    setFilters(newFilters);
    setPage(1); // any change in search should always reset page to 1
    const query = createQueryString({ page: 1, ...newFilters });
    history.push(`/homes${query}`);
    setIsFiltersModalOpen(false);
  };

  const [isInspectionFormModalOpen, setIsInspectionFormModalOpen] = useState(false);
  function handleEditRequestInspection(home) {
    setEditingHomeId(home.id);
    setIsInspectionFormModalOpen(true);
  }

  const customers = useSelector(selectAllCustomerNamesAndIds);

  // initial load and reload when modal is closed (add or edit home)
  useEffect(() => {
    if (!isHomeFormModalOpen && !ekotropeSyncModalIsOpen) loadHomes();
  }, [isHomeFormModalOpen, ekotropeSyncModalIsOpen, page, filters, loadHomes]);

  const getCustomerNameById = useMemo(() => {
    return id => customers.find(p => p.id === id)?.name;
  }, [customers]);

  const homeColumns = [
    {
      dataField: "streetAddress",
      caption: "Address",
      class: "text-left"
    },
    {
      caption: "City",
      dataField: "city"
    },
    {
      caption: "Builder",
      dataField: "customerName",
      cellDisplay: value => (
        <span className="text-sm">{getCustomerNameById(value?.CustomerId)}</span>
      )
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        const canRequestInspection = getRequiredInspectionsNotAlreadyPending(value).length > 0 && value.projectedReports?.length > 0
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`home-view-${value.id}`}
              onClick={e => {
                e.stopPropagation();
                history.push(`/home-details/${value.id}`);
              }}
              Icon={EyeIcon}
              className="text-indigo-600 mr-2 justify-self-end"
            />
            <TableButton
              testId={`home-request-inspection-${value.id}`}
              onClick={e => {
                e.stopPropagation();
                handleEditRequestInspection(value);
              }}
              Icon={PlusCircleIcon}
              className={`text-indigo-600 mr-2 justify-self-end ${canRequestInspection ? "visible" : "invisible"}`}
              disabled={!canRequestInspection}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Homes</h1>
          <div className="flex gap-2">
            <Button onClick={() => setIsFiltersModalOpen(true)}>
              <SearchIcon className="mr-2 h-4 text-white inline-block" />
              Filters {filtersCount ? `(${filtersCount})` : ""}
            </Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={homeColumns}
            className=""
            items={homes || []}
            maxPage={totalPages}
            currentPage={page}
            setPage={handlePageChange}
          />
        </div>
        <Modal modalShowing={isFiltersModalOpen} closeModal={() => setIsFiltersModalOpen(false)}>
          <SearchHomesForm searchHandler={handleSearch} initialValues={filters} />
        </Modal>
        <HomeFormModal
          home={editingHome}
          isOpen={isHomeFormModalOpen}
          onClose={() => setIsHomeFormModalOpen(false)}
          refreshHomes={loadHomes}
        />
        <RequestInspectionFormModal
          home={editingHome}
          isOpen={isInspectionFormModalOpen}
          onClose={() => setIsInspectionFormModalOpen(false)}
          refreshHomes={loadHomes}
        />
      </main>
    </div>
  );
}
