// code is served on 2 domains: one for the main site, and one for the builder site
// some pages and components are conditionally rendered, depending on which site it's on

export const BUILDER_URL = "builder.energycheck.us";

let getIsOnBuilderWebsite;
if (window.location.host.includes("localhost:")) {
  getIsOnBuilderWebsite = () => !!process.env.REACT_APP_BUILDER_SITE; // for local dev testing you can change this into a hard-coded value
} else {
  getIsOnBuilderWebsite = () => window.location.host.includes(BUILDER_URL);
}
export { getIsOnBuilderWebsite };
