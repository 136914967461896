import { unset } from "lodash";

// useful for allowing users to unset values, especially for numbers, which can't be set to empty string
export function convertEmptyStringsToNull(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    newObj[key] = obj[key] === "" ? null : obj[key];
  });
  if (!newObj.id) delete newObj.id; // just in case it's set to null
  return newObj;
}

export default function stripUnfilledValues(values) {
  for (const prop in values) {
    if (!values[prop]) {
      unset(values, prop);
    }
  }
}

export function stripUnfilledValues_pureFunction(values) {
  const cloned = { ...values };
  for (const prop in cloned) {
    if (!cloned[prop]) {
      delete cloned[prop];
    }
  }
  return cloned;
}

export function stringifyIfJSON(arrOrStr) {
  try {
    if (typeof arrOrStr === "string") return arrOrStr;
    else return JSON.stringify(arrOrStr);
  } catch (err) {
    return arrOrStr;
  }
}
