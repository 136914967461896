import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon, SearchIcon, TrashIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import { deleteCustomerAsync } from "../../state/slices/customerSlice";
import * as Customer from "../../services/customer";
import { openModal } from "../../state/slices/frontend";
import { TableWithPagination } from "../common/Table";
import { GreenButton as Button, SmallGreenButton, SmallRedButton } from "../common/Buttons";
import TableButton from "../common/TableButton";
import FormHeader from "../common/forms/FormHeader";
import Modal from "../common/Modal";
import {
  createQueryString,
  getPageFromSearchParams,
  getStartingFilters,
} from "../../utils/searchParams";
import { flashSuccessNotification } from "../../state/slices/notificationSlice";
import { BUILDER_URL } from "../../utils/isBuilderWebsite";

export default function Customers() {
  const dispatch = useDispatch();
  const history = useHistory();
  const startingPage = getPageFromSearchParams(history?.location?.search) || 1;
  const [page, setPage] = useState(startingPage);
  const [customers, setCustomers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const startingFilters = getStartingFilters(history?.location?.search) || {};
  const [filters, setFilters] = useState(startingFilters);
  const filtersCount = useMemo(() => Object.values(filters).filter(Boolean).length, [filters]);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const isCustomerModalOpen = useSelector(state => state.frontend.modals.customer.isOpen);

  const loadCustomers = useCallback(async () => {
    const { customers, pages } = await Customer.getAll({ page, ...filters });
    setCustomers(customers);
    setTotalPages(pages);
  }, [page, filters]);

  const handlePageChange = newPage => {
    const query = createQueryString({ page: newPage, ...filters });
    history.push(`/builders${query}`);
    setPage(() => newPage);
  };

  const handleSearch = async newFilters => {
    setFilters(newFilters);
    setPage(1); // any change in search should always reset page to 1
    const query = createQueryString({ page: 1, ...newFilters });
    history.push(`/builders${query}`);
    setIsFiltersModalOpen(false);
  };

  // initial load and when page or filters change or modal is closed (add or edit customer)
  useEffect(() => {
    if (!isCustomerModalOpen) loadCustomers();
  }, [dispatch, isCustomerModalOpen, loadCustomers]);

  const handleEditCustomer = customer => {
    dispatch(openModal({ modal: "customer", target: customer }));
  };
  const handleAddCustomer = () => {
    dispatch(openModal({ modal: "customer" }));
  };

  const handleDelete = customer => {
    const confirm = window.confirm("Deleting a customer is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deleteCustomerAsync(customer));
      loadCustomers();
    }
  };

  const userColumns = [
    {
      dataField: "name",
      class: "text-left",
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`customer-edit-${value.id}`}
              onClick={() => handleEditCustomer(value)}
              Icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />

            <TableButton
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `https://${BUILDER_URL}/auth/builder/signup?customerId=${value.id}`,
                );
                dispatch(flashSuccessNotification("Signup link copied to clipboard."));
              }}
              Icon={ClipboardCopyIcon}
              className="text-blue-500 mr-2"
            >
              copy signup link
            </TableButton>

            <TableButton
              testId={`customer-delete-${value.id}`}
              onClick={() => handleDelete(value)}
              Icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Builders</h1>
          <div className="flex gap-2">
            <Button onClick={() => setIsFiltersModalOpen(true)}>
              <SearchIcon className="mr-2 h-4 text-white inline-block" />
              Filters {filtersCount ? `(${filtersCount})` : ""}
            </Button>
            <Button onClick={handleAddCustomer}>Add</Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={userColumns}
            className=""
            items={customers || []}
            currentPage={page}
            setPage={handlePageChange}
            maxPage={totalPages}
          />
        </div>
        <Modal modalShowing={isFiltersModalOpen} closeModal={() => setIsFiltersModalOpen(false)}>
          <SearchCustomersForm searchHandler={handleSearch} initialValues={filters} />
        </Modal>
      </main>
    </div>
  );
}

export function SearchCustomersForm({ searchHandler, initialValues = {} }) {
  const [state, setState] = useState({
    name: initialValues.name || "",
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    searchHandler(state);
  };

  const handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    searchHandler({});
  };

  return (
    <>
      <form onSubmit={onSubmitHandler} className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg">
        <FormHeader className="col-span-2">Search Builders</FormHeader>
        <div className="col-span-2">
          <label htmlFor="name" className="text-gray-900">
            Builder Name
          </label>
          <input
            autoFocus
            id="name"
            name="name"
            onChange={handleChange}
            value={state.name}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} type="button" className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}
