import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignInPage from "./components/auth/SignIn";
import SignUpPage from "./components/auth/SignUp";
import BuilderSignUpPage from "./components/auth/BuilderSignUp";
import ForgotPasswordPage from "./components/auth/Forgot";
import ResetPasswordPage from "./components/auth/Reset";
import Navbar from "./components/nav/Navbar";
import Users from "./components/pages/Users";
import Customers from "./components/pages/Customers";
import { checkAuthStatusAsync } from "./state/slices/authSlice";
import { readAllCustomerOptions, readCustomerContactOptions } from "./state/slices/customerSlice";
import { readUserOptionsAsync } from "./state/slices/userSlice";
import store from "./state/store/store";
import HomePage from "./components/pages/HomePage";
import Homes from "./components/pages/Homes";
import Inspections from "./components/pages/Inspections";
import Notification from "./components/common/Notification";
import { getIsOnBuilderWebsite } from "./utils/isBuilderWebsite";
import FloorPlans from "./components/pages/FloorPlans";
import CityForms from "./components/pages/CityFormsPage";
import { readAllFloorPlanOptions } from "./state/slices/floorPlanSlice";
import { readAllCityFormOptions } from "./state/slices/cityFormSlice";
import HomeDetails from "./components/pages/HomeDetails";

// export for test (App.test.js), for dev and prod use the default export WrappedApp
export function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector(state => state.auth);
  const currentUser = useSelector(state => state.auth.currentUser);

  const isOnBuilderWebsite = getIsOnBuilderWebsite();

  const isBuilderUser = currentUser?.role === "builder";
  const isRegularUser = currentUser?.role === "user";

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(readAllCustomerOptions());
      dispatch(readCustomerContactOptions());
      dispatch(readUserOptionsAsync());
      dispatch(readAllCityFormOptions());
      dispatch(readAllFloorPlanOptions());
    } else {
      dispatch(checkAuthStatusAsync());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <Notification />
      {!isAuthenticated && !loading && (
        <Switch>
          {!isOnBuilderWebsite && <Route exact path="/auth/signup" component={SignUpPage} />}
          {isOnBuilderWebsite && (
            <Route exact path="/auth/builder/signup" component={BuilderSignUpPage} />
          )}
          <Route exact path="/auth/forgot" component={ForgotPasswordPage} />
          <Route exact path="/auth/reset" component={ResetPasswordPage} />
          {/* Public pages */}
          {/* the default route when unauthenticated */}
          <Route path="/" component={SignInPage} />
        </Switch>
      )}
      {isAuthenticated && (
        <div className="bg-gray-200 min-h-screen">
          <Navbar />
          {!isOnBuilderWebsite && isRegularUser && (
            <Switch>
              <Route exact path="/homes" component={Homes} />
              <Route exact path="/inspections" component={Inspections} />
              <Route path="/home-details/:id" component={HomeDetails} />
              <Route exact path="/floor-plans" component={FloorPlans} />
              <Route exact path="/city-forms" component={CityForms} />

              <Route path="/users" component={Users} />
              <Route path="/builders" component={Customers} />
              <Route exact path="/" component={HomePage} />
            </Switch>
          )}
          {isOnBuilderWebsite && isBuilderUser && (
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/home-details/:id" component={HomeDetails} />
            </Switch>
          )}
        </div>
      )}
    </>
  );
}

export function StatefulWrapperWithRouter({ children }) {
  return (
    <Provider store={store}>
      <Router>{children}</Router>
    </Provider>
  );
}

function WrappedApp() {
  return (
    <StatefulWrapperWithRouter>
      <App />
    </StatefulWrapperWithRouter>
  );
}

export default WrappedApp;
