import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// seems not to work so we have to hardcode the CDN_URL
export const CDN_URL =
  process.env.REACT_APP_CDN_URL || "https://ik.imagekit.io/zonijteiooy/energycheck";

export function PDFPreview() {
  const src = useSelector(state => state.frontend.modals.pdf.target);
  const documentUrl = CDN_URL + src; // src is like "/attachments/something.pdf"
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  return (
    <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
}
