import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon } from "@heroicons/react/outline";
import { TableWithPagination } from "../common/Table";
import { readUsersAsync } from "../../state/slices/userSlice";
import { openModal } from "../../state/slices/frontend";
import TableButton from "../common/TableButton";

export default function Users() {
  const { users } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(readUsersAsync({ page: currentPage }));
  }, [dispatch, currentPage]);

  const handleEditUser = user => {
    dispatch(openModal({ modal: "user", target: user }));
  };

  const userColumns = [
    {
      dataField: "name",
      class: "text-left",
    },
    {
      dataField: "email",
      class: "text-left",
    },
    {
      caption: "RTIN",
      class: "text-left",
      cellDisplay(value) {
        return <div className="text-left">{value.RTIN}</div>;
      },
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`user-edit-${value.id}`}
              onClick={() => handleEditUser(value)}
              className="text-indigo-600"
              Icon={PencilIcon}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-white">Users</h1>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={userColumns}
            className=""
            items={users || []}
            onRowClick={handleEditUser}
            currentPage={currentPage}
            setPage={setCurrentPage}
          />
        </div>
      </main>
    </div>
  );
}
