import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { openModal } from "../../state/slices/frontend";
import { TableWithPagination } from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";
import * as FloorPlanService from "../../services/floor-plan";
import axios from "axios";
import { selectAllCustomerNamesAndIds } from "../../state/slices/customerSlice";

export default function FloorPlans() {
  const [floorPlans, setFloorPlans] = useState([]);
  const dispatch = useDispatch();
  const floorPlanModalIsOpen = useSelector(state => state.frontend.modals.floorPlan.isOpen);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const customers = useSelector(selectAllCustomerNamesAndIds);

  const getCustomerNameById = useMemo(() => {
    return id => customers.find(p => p.id === id)?.name;
  }, [customers]);

  const loadFloorPlans = useCallback(async () => {
    const { rows, pages } = await FloorPlanService.getAll({ page });
    setFloorPlans(rows);
    setTotalPages(pages);
  }, [page]);

  // initial load and reload when modal is closed (add or edit floor plan)
  useEffect(() => {
    if (!floorPlanModalIsOpen) loadFloorPlans();
  }, [floorPlanModalIsOpen, loadFloorPlans]);

  const handleEditFloorPlan = floorPlan => {
    dispatch(openModal({ modal: "floorPlan", target: floorPlan }));
  };
  const handleAddFloorPlan = () => {
    dispatch(openModal({ modal: "floorPlan" }));
  };

  const handleDelete = async (e, floorPlan) => {
    e.stopPropagation();
    const confirm = window.confirm("Deleting a floor plan is irreversible.\nAre you sure?");
    if (!confirm) return;

    await axios.delete("/api/floor-plan/" + floorPlan.id);
    loadFloorPlans();
  };

  const floorPlanColumns = [
    {
      dataField: "name",
      caption: "Name",
      class: "text-left",
    },
    {
      caption: "Builder",
      dataField: "customerName",
      cellDisplay: value => (
        <span className="text-sm">{getCustomerNameById(value?.CustomerId)}</span>
      ),
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`floor-plan-edit-${value.id}`}
              onClick={() => handleEditFloorPlan(value)}
              Icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />

            <TableButton
              testId={`floor-plan-delete-${value.id}`}
              onClick={e => handleDelete(e, value)}
              Icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Floor Plans</h1>
          <div className="flex gap-2">
            <Link to="/floor-plans/search">
              <Button>
                <SearchIcon className="mr-2 h-4 text-white inline-block" />
                Search
              </Button>
            </Link>
            <Button onClick={handleAddFloorPlan}>Add</Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <TableWithPagination
            columns={floorPlanColumns}
            className=""
            onRowClick={handleEditFloorPlan}
            items={floorPlans || []}
            maxPage={totalPages}
            currentPage={page}
            setPage={setPage}
          />
        </div>
      </main>
    </div>
  );
}
