import React from "react";

export const buttonVariants = {
  blue: "blue",
  green: "green",
  red: "red",
  gray: "gray",
};

function getColorClasses(variant) {
  if (variant === buttonVariants.blue) {
    return "bg-blue-600 hover:bg-blue-700 text-white";
  } else if (variant === buttonVariants.green) {
    return "bg-green-600 hover:bg-green-700 text-white";
  } else if (variant === buttonVariants.red) {
    return "bg-red-600 hover:bg-red-700 text-white";
  } else if (variant === buttonVariants.gray) {
    return "bg-gray-600 hover:bg-gray-700 text-white";
  } else {
    throw new Error("Button variant not recognized");
  }
}

const Button = ({
  onClick,
  children,
  variant = buttonVariants.blue,
  small,
  className,
  type,
  disabled,
  testId,
}) => {
  const defaultStyles = "rounded-md text-sm shadow-sm";
  const disabledStyle = "bg-gray-400 text-white cursor-default";
  const colorStyle = disabled ? disabledStyle : getColorClasses(variant);
  const sizeStyle = small ? "py-2 px-4 font-medium" : "py-3 px-5 font-bold";
  // append props classes to defaults
  const style = `${defaultStyles} ${colorStyle} ${sizeStyle} ${className ?? ""}`;
  return (
    <button
      onClick={onClick}
      className={style}
      type={type}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </button>
  );
};

export default Button;

export function GreenButton({ onClick, children, className = "", disabled }) {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant={buttonVariants.green}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function RedButton({ onClick, children, className = "", disabled }) {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant={buttonVariants.red}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function GrayButton({ onClick, children, className = "", disabled }) {
  return (
    <Button
      onClick={onClick}
      className={className}
      variant={buttonVariants.gray}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export function SmallGreenButton({ onClick, children, className = "", type }) {
  return (
    <Button
      onClick={onClick}
      className={className}
      type={type}
      variant={buttonVariants.green}
      small
    >
      {children}
    </Button>
  );
}

export function SmallRedButton({ onClick, children, className = "", type }) {
  return (
    <Button onClick={onClick} className={className} type={type} variant={buttonVariants.red} small>
      {children}
    </Button>
  );
}

export function SubmitButton({ onClick = () => {}, testid, children }) {
  // onClick is optional because type="submit" makes the form onSubmit event fire
  return (
    <Button type="submit" onClick={onClick} testId={testid}>
      {children || "Submit"}
    </Button>
  );
}
