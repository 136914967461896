import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import FormHeader from "../common/forms/FormHeader";
import { WithSpacesBetween } from "../common/list-wrappers";
import { SubmitButton } from "../common/Buttons";
import { closeModal, modifyModal } from "../../state/slices/frontend";
// import * as CityFormService from "../../services/cityForm";
import MultipleFileUploader from "./MultipleFileUploader";
import { upsertCityFormAsync } from "../../state/slices/cityFormSlice";
import { convertEmptyStringsToNull } from "../../utils/formUtils";
import { USAStates } from "../../utils/constants";

export default function StatefulCityFormForm() {
  const dispatch = useDispatch();
  const cityForm = useSelector(state => state.frontend.modals.cityForm.target || {});
  const [isModified, setIsModified] = React.useState(false);

  React.useEffect(() => {
    if (isModified) dispatch(modifyModal({ modal: "cityForm" }));
  }, [isModified, dispatch]);

  const initialValues = {
    id: cityForm.id || "",
    city: cityForm.city || "",
    state: cityForm.state || "",
    attachments: cityForm.attachments || [],
  };

  const handleSubmit = async cityForm => {
    // TODO: validate city and state are unique
    // if nothing changed, skip validation and upsert
    // const shouldSkipValidation = initialValues.city === cityForm.city && initialValues.state === cityForm.state;
    // if (!shouldSkipValidation) {
    //   const isValid = await CityFormService.validateCityStateUnique(cityForm);
    //   if (!isValid) return
    // }
    const payload = convertEmptyStringsToNull(cityForm);
    await dispatch(upsertCityFormAsync(payload));
    dispatch(closeModal({ modal: "cityForm" }));
  };

  return (
    <CityFormForm
      initialValues={initialValues}
      handleModified={() => setIsModified(true)}
      handleSubmit={handleSubmit}
    />
  );
}

export function CityFormForm({ handleSubmit, handleModified, initialValues }) {
  // Validation rules
  const CityFormSchema = Yup.object().shape({
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={CityFormSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values, handleChange }) => {
        function handleInputChange(e) {
          handleModified();
          handleChange(e);
        }

        return (
          <>
            {initialValues.id ? (
              <FormHeader className="mt-4">Edit City Form</FormHeader>
            ) : (
              <div className="flex gap-4">New City Form</div>
            )}
            <Form>
              <WithSpacesBetween>
                <div className="grid grid-cols-2 gap-2">
                  <FormikFieldWithLabel
                    className="col-span-1"
                    name="city"
                    label="City"
                    onChange={handleInputChange}
                    error={touched.city && errors.city}
                  />
                  <FormikFieldWithLabel
                    as="select"
                    name="state"
                    label="State"
                    onChange={handleInputChange}
                    error={touched.state && errors.state}
                  >
                    <option value="">---</option>
                    {USAStates.map(state => (
                      <option value={state} key={state}>
                        {state}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <MultipleFileUploader
                    files={values.attachments}
                    onUpload={value =>
                      handleInputChange({ target: { name: "attachments", value } })
                    }
                  />
                </div>
              </WithSpacesBetween>

              <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
                <SubmitButton />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
