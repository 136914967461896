import Modal from "../common/Modal";
import RequestInspectionForm from "./RequestInspectionForm";

export default function RequestInspectionFormModal({
  home = {},
  isOpen,
  onClose,
  refreshHomes,
  editingInspection,
}) {
  return (
    <Modal modalShowing={isOpen} closeModal={() => onClose()}>
      <RequestInspectionForm
        home={home}
        closeModal={() => onClose()}
        refreshHomes={refreshHomes}
        editingInspection={editingInspection}
      />
    </Modal>
  );
}
