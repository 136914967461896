import axios from "axios";

export const upsert = async floorPlan => {
  const route = "/api/floor-plan";
  const { data } = await axios.post(route, floorPlan);
  return data;
};

export const getAll = async () => {
  const { data } = await axios.get("/api/floor-plans");
  return data;
};

export const getAllOptions = async () => {
  const { data } = await axios.get("/api/floor-plan/options");
  return data;
};

export async function validateNameUnique(name) {
  const route = "/api/floor-plan/validate";
  const response = await axios.get(route, { params: { name } });
  return response.data;
}
