import React from "react";

export default function TableButton({ Icon, testId, onClick, className, ...rest }) {
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className={`h-8 w-8 hover:bg-gray-200 p-2 rounded-md ${className}`}
      {...rest}
    >
      {Icon && <Icon />}
    </button>
  );
}
