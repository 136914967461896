import React from "react";
import { Link } from "react-router-dom";
import {
  ArrowRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Field, Form, Formik } from "formik";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import { SubmitButton } from "../common/Buttons";
import * as Yup from "yup";
import stripUnfilledValues from "../../utils/formUtils";
import { getRequiredInspectionsNotAlreadyPending } from "../pages/BuilderHomes";
import { DateFieldWithError } from "../common/Field";
import InspectionService from "../../services/inspection";
import HomeService from "../../services/home";
import { inspectionOptions } from "../../services/inspection";

export function getInspectionTypeStringsByStatus(home, status) {
  if (status === "scheduled" || status === "requested") {
    return home.Inspections.filter(inspection => inspection.status === status)
      .map(inspection => inspectionOptions[inspection.inspectionType])
      .join(", ");
  } else if (status === "passed") {
    const passingOptions = [
      "pass",
      "passWithCorrectionsProceed",
      "passWithCorrectionCheckAtInsulation",
    ];
    return home.Inspections.filter(
      inspection =>
        inspection.status === "completed" && passingOptions.includes(inspection.passFail),
    )
      .map(inspection => inspectionOptions[inspection.inspectionType])
      .join(", ");
  } else if (status === "failed") {
    const failingOptions = ["fail", "nri", "correctAndReschedule", "cancelledByBuilder"];
    return home.Inspections.filter(
      inspection =>
        inspection.status === "completed" && failingOptions.includes(inspection.passFail),
    )
      .map(inspection => inspectionOptions[inspection.inspectionType])
      .join(", ");
  }
}

export default function RequestInspectionForm({
  home,
  closeModal,
  refreshHomes,
  editingInspection,
}) {
  const initialValues = {
    houseAccessNotes: home.houseAccessNotes || "",
    requestedFor: (editingInspection && new Date(editingInspection.requestedFor)) || "",
    inspectionType: editingInspection?.inspectionType || "",
    requestorNotes: editingInspection?.requestorNotes || "",
  };

  const InspectionSchema = Yup.object().shape({
    inspectionType: Yup.string().required("Required"),
    requestedFor: Yup.date().nullable().notRequired().typeError("Invalid date"),
    requestorNotes: Yup.string(),
    houseAccessNotes: Yup.string(),
  });

  const handleSubmitInspection = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    try {
      // 1. upsert the home with the updated houseAccessNotes
      await HomeService.upsert({ ...home, houseAccessNotes: payload.houseAccessNotes });
      // 2. create a new inspection
      if (editingInspection) {
        await InspectionService.update({ ...payload, id: editingInspection.id, HomeId: home.id });
      } else {
        await InspectionService.create({ ...payload, HomeId: home.id, status: "requested" });
      }
      refreshHomes();
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <div className="flex items-center justify-between mt-2">
        <h1 className="text-2xl font-bold">
          {editingInspection
            ? `Edit Inspection: ${inspectionOptions[editingInspection.inspectionType]}`
            : "Request an Inspection"}
        </h1>
        {!editingInspection && (
          <Link className="flex items-center" to={`/home-details/${home.id}`}>
            Home Details
            <ArrowRightIcon className="h-3 w-3 m-2" style={{ rotate: "-45deg" }} />
          </Link>
        )}
      </div>
      {!editingInspection && (
        <div className="px-4 pb-4 mt-4 mb-8 bg-gray-100 rounded-md">
          <h2 className="py-3 text-md font-semibold border-b border-gray-300">
            Required inspections:{" "}
            {home.requiredInspections?.map(inspection => inspectionOptions[inspection]).join(", ")}
          </h2>

          <div className="flex flex-col space-y-2 mt-4">
            {getInspectionTypeStringsByStatus(home, "passed").length > 0 && (
              <div className="flex items-start md:items-center flex-col md:flex-row">
                <h3 className="text-md font-semibold mr-2 flex items-center">
                  <CheckCircleIcon className="h-5 w-5 mr-2 text-green-500" /> Passed Inspections:
                </h3>
                <p className="text-gray-800">{getInspectionTypeStringsByStatus(home, "passed")}</p>
              </div>
            )}
            {getInspectionTypeStringsByStatus(home, "failed").length > 0 && (
              <div className="flex items-start md:items-center flex-col md:flex-row">
                <h3 className="text-md font-semibold mr-2 flex items-center">
                  <XCircleIcon className="h-5 w-5 mr-2 text-red-500" />
                  Failed Inspections:
                </h3>
                <p className="text-gray-800">{getInspectionTypeStringsByStatus(home, "failed")}</p>
              </div>
            )}
            {getInspectionTypeStringsByStatus(home, "requested").length > 0 && (
              <div className="flex items-start md:items-center flex-col md:flex-row">
                <h3 className="text-md font-semibold mr-2 flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 mr-2 text-yellow-500" />
                  Requested Inspections:
                </h3>
                <p className="text-gray-800">
                  {getInspectionTypeStringsByStatus(home, "requested")}
                </p>
              </div>
            )}
            {getInspectionTypeStringsByStatus(home, "scheduled").length > 0 && (
              <div className="flex items-start md:items-center flex-col md:flex-row">
                <h3 className="text-md font-semibold mr-2 flex items-center">
                  <InformationCircleIcon className="h-5 w-5 mr-2 text-blue-500" />
                  Scheduled Inspections:
                </h3>
                <p className="text-gray-800">
                  {getInspectionTypeStringsByStatus(home, "scheduled")}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={InspectionSchema}
        className="p-4"
        onSubmit={handleSubmitInspection}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="grid grid-cols-12">
              <FormikFieldWithLabel
                autoFocus
                as="select"
                label="Inspection Type"
                className="col-span-6"
                name="inspectionType"
                error={touched.inspectionType && errors.inspectionType}
                disabled={editingInspection}
              >
                <option value="">
                  {editingInspection ? inspectionOptions[editingInspection.inspectionType] : "---"}
                </option>
                {getRequiredInspectionsNotAlreadyPending(home)?.map(type => (
                  <option value={type} key={type}>
                    {inspectionOptions[type]}
                  </option>
                ))}
              </FormikFieldWithLabel>
              <Field
                component={DateFieldWithError}
                type="date"
                name="requestedFor"
                className="col-span-6"
                label="Date"
                error={touched.requestedFor && errors.requestedFor}
                tooltip="If you want to specify a date range or time range, use the Inspection notes field."
                minDate={tomorrow}
                value={values.requestedFor}
              />
              <FormikFieldWithLabel
                as="textarea"
                placeholder="Enter notes for the scheduler or inspector here"
                name="requestorNotes"
                label="Inspection Notes"
                className="col-span-12"
                error={touched.requestorNotes && errors.requestorNotes}
              />
              <FormikFieldWithLabel
                as="textarea"
                placeholder="Enter any notes for the inspector here"
                name="houseAccessNotes"
                label="House Access Details"
                tooltip="Editing this field will update it for all the inspections for this home."
                className="col-span-12"
                error={touched.houseAccessNotes && errors.houseAccessNotes}
              />
            </div>
            <div className="mt-6 mb-4">
              <SubmitButton>
                {editingInspection ? "Edit Inspection" : "Request Inspection"}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
