import React from "react";
import { Link } from "react-router-dom";

export default function Logo({ className }) {
  return (
    <Link to="/">
      <img
        className={`h-10 w-10 mx-auto bg-white rounded-full ${className}`}
        src="/assets/energy.png"
        alt="Logo for energycheck"
      />
    </Link>
  );
}
