import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PlusCircleIcon, TrashIcon, DownloadIcon } from "@heroicons/react/outline";
import {
  uploadAttachmentToStorj,
  getFileNameFromAttachmentPath,
} from "../../services/image-upload";
import { openModal } from "../../state/slices/frontend";
import { CDN_URL } from "../common/PDFPreview";

const MultipleFileUploader = ({ files = [], onUpload, title }) => {
  const dispatch = useDispatch();
  const inputRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  // generate random id for input element in case there's more than one on the page
  const inputId = React.useMemo(() => "file" + Math.random().toString(36).substring(7), []);

  useEffect(() => {
    function handleUpload(filePath) {
      const newFiles = [...files];
      newFiles.push(filePath);
      onUpload(newFiles);
    }

    const element = inputRef.current;

    const onChange = async event => {
      if (event.target.files && event.target.files.length > 0) {
        // setLoading(true);
        const file = event.target.files[0];
        try {
          setLoading(true);
          const fileKey = await uploadAttachmentToStorj(file);
          setLoading(false);
          handleUpload(fileKey);
          // closeModal();
        } catch (err) {
          console.error("Failed to upload attachment", err);
          // dispatch(flashErrorNotification("Failed to upload your photo."));
        }
      }
    };
    element.addEventListener("change", onChange);

    return () => {
      if (!element) return;
      element.removeEventListener("change", onChange);
    };
  }, [files, onUpload]);

  function handleDelete(e, fileIndex) {
    e.stopPropagation();
    // note: we don't want to actually delete the file from storj, just from the list
    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    onUpload(newFiles);
  }

  return (
    <div>
      <h2 className="text-md text-gray-900">{title}</h2>
      {files.map((file, index) => (
        <div
          key={index}
          className="flex items-center justify-between space-x-2 rounded border p-2 mt-2 cursor-pointer"
          onClick={() => dispatch(openModal({ modal: "pdf", target: file }))}
        >
          <span className="text-sm text-gray-900">{getFileNameFromAttachmentPath(file)}</span>
          <div className="flex items-center justify-between space-x-2">
            <a
              href={`${CDN_URL}${file}`}
              target="_blank"
              onClick={e => e.stopPropagation()}
              rel="noopener noreferrer"
              className="p-2 rounded-md text-sm text-blue-600 hover:bg-gray-200"
              data-testid="download-attachment"
            >
              <DownloadIcon className="h-4 w-4" />
            </a>
            <button
              className="p-2 rounded-md text-sm text-red-500 hover:bg-gray-200"
              onClick={e => handleDelete(e, index)}
            >
              <TrashIcon className="h-4 w-4" data-testid="delete-attachment" />
            </button>
          </div>
        </div>
      ))}
      {loading ? (
        <p className="py-8 text-gray-800">Uploading...</p>
      ) : (
        <label htmlFor={inputId} className="block cursor-pointer p-6" data-testid="add-attachment">
          <PlusCircleIcon className="h-10 w-10 text-gray-700" />
        </label>
      )}
      <input
        id={inputId}
        ref={inputRef}
        type="file"
        name="file"
        className="hidden"
        accept="image/*,application/pdf"
      />
    </div>
  );
};

export default MultipleFileUploader;
